import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageSelector from '../../components/LanguageSelector';
import { Collapse } from 'react-bootstrap';
import AccountMenu from './AccountMenu';
import { selectCurrentAccount, updatePersonalDetails } from '../../features/account/accountSlice';
import { logoutUser } from '../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';
import LogoImage from '../../assets/images/logo.png';

const Header = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const user = useSelector(selectCurrentAccount);
    const [showNavigation, setShowNavigation] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('home');

    const handleSelectLanguage = (culture) => {
        dispatch(updatePersonalDetails({ cultureName: culture }));
    }

    const languageSelector = <LanguageSelector onSelect={handleSelectLanguage} />;

    return <header id='headerBasic' className='header'>
        <div className='header-section'>

            <div id='basicLogoAndNav' className='container'>
                {/* Nav */}
                <nav className='navbar navbar-expand-lg'>
                    {/* Logo */}
                    <Link className='navbar-brand navbar-nav-wrap-brand' to='/'>
                        <img src={LogoImage} alt='Stress-Less' />
                    </Link>
                    {/* End Logo */}

                    <div className='navbar-nav-wrap-content'>
                        <AccountMenu user={user} onLogout={() => dispatch(logoutUser())} />
                    </div>
                    <div className='navbar-nav-wrap-content d-none d-md-block' >
                        {languageSelector}
                    </div>
                    {/* Responsive Toggle Button */}
                    <button type='button' className={`navbar-toggler btn btn-icon btn-sm rounded-circle ${!showNavigation ? 'collapsed' : ''}`}
                        aria-label='Toggle navigation'
                        aria-expanded={showNavigation}
                        aria-controls='navBarBasic'
                        onClick={() => setShowNavigation(!showNavigation)}>
                        <span className='navbar-toggler-default'>
                            <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"></path>
                            </svg>
                        </span>
                        <span className='navbar-toggler-toggled'>
                            <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"></path>
                            </svg>
                        </span>
                    </button>
                    {/* End Responsive Toggle Button */}

                    {/* Navigation */}
                    <Collapse id='navBarBasic' in={showNavigation}>
                        <div className='navbar-collapse'>
                            <ul className='navbar-nav'>
                                <li className={`navbar-nav-item ${activeMenuItem === 'home' ? 'active' : ''}`}>
                                    <Link className='nav-link' to='/' onClick={() => setActiveMenuItem('home')}>{t('MainMenu_Home')}</Link>
                                </li>
                                <li className='navbar-nav-item' >
                                    <a className='nav-link' target='_blank' rel='noreferrer'
                                        href={t('Project_Url')} onClick={() => setActiveMenuItem('project')}>{t('MainMenu_Project')}</a>
                                </li>
                                <li className={`navbar-nav-item ${activeMenuItem === 'program' ? 'active' : ''}`}>
                                    <Link className='nav-link' to='/cms/stress-less-program' onClick={() => setActiveMenuItem('program')}>{t('MainMenu_Program')}</Link>
                                </li>
                                <li className='navbar-nav-item' >
                                    <a className='nav-link' target='_blank' rel='noreferrer'
                                        href={t('Contacts_Url')} onClick={() => setActiveMenuItem('contacts')}>{t('MainMenu_Contacts')}</a>
                                </li>
                                <li className='navbar-nav-item mt-2 d-md-none' >
                                    {languageSelector}
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                    {/* End Navigation */}
                </nav>
                {/* End Nav */}
            </div>
        </div>
    </header >;
};

export default Header;