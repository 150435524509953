import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import HighImage from '../../assets/images/points_high.png';
import LowImage from '../../assets/images/points_low.png';

const PointListItem = ({ item }) => {
    const { t } = useTranslation();
    const { amount, valueDate, description, offer } = item;

    const name = offer ? offer.name : description ? description : (amount > 0 ? t('Points_Earned') : t('Points_Redeemed'));
    const imageUrl = amount > 0 ? HighImage : LowImage;
    const absAmount = Math.abs(amount);

    return <Card className='card-bordered shadow-none mb-3'>
        <Card.Body>
            <div className='d-sm-flex'>
                <div className='media align-items-center align-items-sm-start mb-3'>
                    <img className='avatar avatar-sm mr-3' src={imageUrl} alt='Activity' />
                </div>
                <div className='media-body'>
                    <Row>
                        <Col md={8}>
                            <h3 className={'mb-0 text-dark'}>{name}</h3>
                            <div className='d-none d-sm-inline-block'>
                                <h6 className='mb-0 text-body'>
                                    <FontAwesomeIcon icon='calendar-alt' className='mb-0' /> <span>{moment(valueDate).format('L HH:mm')}</span>
                                </h6>
                            </div>
                        </Col>

                        <Col className='col-md mt-3 mt-md-0 h3'>
                            <span className={`badge ${amount > 0 ? 'badge-soft-info' : 'badge-danger'}`}>
                                {t('Points_Point', { sign: amount > 0 ? '+' : '-', count: absAmount })}
                            </span>
                        </Col>
                    </Row>
                </div>
            </div>
        </Card.Body>
    </Card>
};

export default PointListItem;