import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormLabel, FormControl, FormText } from 'react-bootstrap';
import utils from '../../services/utils';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import globalize from '../../services/globalize';

const DateTimeInput = ({ showTime = false, dateFormat, horizontal, required, input: { onChange, value, name },
    labelCol = 3, valueCol = 9, label, type, help, meta: { touched, error, warning, submitError }, disabled, ...props }) => {
    const isInvalid = touched && (error || submitError || warning);

    var dateValue = null;
    if (value) {
        dateValue = utils.parseDateTime(value, dateFormat);
    }

    const inputProps = {
        disabled,
        className: `form-control ${isInvalid ? 'is-invalid' : ''}`
    };
    const formControl = <Datetime name={name} onChange={onChange} value={dateValue}
        timeFormat={showTime} locale={globalize.getCurrentCulture()}
        className={isInvalid ? 'is-invalid' : ''} inputProps={inputProps}
        {...props} />;

    const errorBlock = isInvalid && <FormControl.Feedback type='invalid'>{error || submitError || warning}</FormControl.Feedback>;
    const helpBlock = help && <FormText muted>{help}</FormText>;
    const labelHint = required && <em style={{ color: '#D62229' }}>*</em>;

    if (horizontal) {
        return (
            <FormGroup className='row'>
                <Col as={FormLabel} sm={labelCol} className='col-form-label input-label'>
                    {label}{labelHint}
                </Col>
                <Col sm={valueCol}>
                    {formControl}
                    {errorBlock}
                    {helpBlock}
                </Col>
            </FormGroup>);
    }

    return (
        <FormGroup>
            <FormLabel className='input-label'>{label}{labelHint}</FormLabel>
            {formControl}
            {errorBlock}
            {helpBlock}
        </FormGroup>
    );
};

DateTimeInput.propTypes = {
    showTime: PropTypes.bool,
    horizontal: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    help: PropTypes.string,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    componentClass: PropTypes.string,
    value: PropTypes.string,
    containerClassName: PropTypes.string
};

export default DateTimeInput;