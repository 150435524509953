import React from 'react';
import { Link } from 'react-router-dom';
import LanguageSelector from '../../components/LanguageSelector';
import LogoImage from '../../assets/images/logo.png';

const Header = () => (
    <header id='header' className='header header-bg-transparent header-abs-top'>
        <div className='header-section'>
            <div id='logoAndNav' className='container-fluid'>
                {/* Nav */}
                <nav className='navbar navbar-expand header-navbar'>
                    {/* White Logo */}
                    <Link className='d-none d-lg-flex navbar-brand header-navbar-brand' to='/'>
                        <img src={LogoImage} alt='Logo' />
                    </Link>
                    {/* End White Logo */}

                    {/* Default Logo */}
                    <Link className='d-flex d-lg-none navbar-brand header-navbar-brand header-navbar-brand-collapsed' to='/'>
                        <img src={LogoImage} alt='Logo' />
                    </Link>
                    {/* End Default Logo */}

                    {/* Button */}
                    <div className='ml-auto'>
                        <LanguageSelector />
                    </div>
                    {/* End Button */}
                </nav>
                {/* End Nav */}
            </div>
        </div>
    </header>
);

export default Header;