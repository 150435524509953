import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormLabel, FormControl, InputGroup, FormText } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Input = ({ horizontal, icon, required, input, labelCol = 3, valueCol = 9, label, help, meta: { touched, error, warning, submitError }, children, ...props }) => {
    const isInvalid = touched && (error || submitError || warning);

    var formControl = <FormControl {...input} {...props} isInvalid={isInvalid}>{children}</FormControl>;
    if (icon) {
        formControl = <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text><FontAwesomeIcon icon={icon} /></InputGroup.Text>
            </InputGroup.Prepend>
            {formControl}
        </InputGroup>;
    }
    const errorBlock = isInvalid && <FormControl.Feedback type='invalid'>{error || submitError || warning}</FormControl.Feedback>;
    const helpBlock = help && <FormText muted>{help}</FormText>;
    const labelHint = required && <em style={{ color: '#D62229' }}>*</em>;

    if (horizontal) {
        return (
            <FormGroup className='row'>
                <Col as={FormLabel} sm={labelCol} className='col-form-label input-label'>
                    {label}{labelHint}
                </Col>
                <Col sm={valueCol}>
                    {formControl}
                    {errorBlock}
                    {helpBlock}
                </Col>
            </FormGroup>);
    }

    return (
        <FormGroup>
            <FormLabel className='input-label'>{label}{labelHint}</FormLabel>
            {formControl}
            {errorBlock}
            {helpBlock}
        </FormGroup>
    );
};

Input.propTypes = {
    horizontal: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    help: PropTypes.string,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    componentClass: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.string,
};

export default Input;