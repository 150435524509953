import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import Config from '../../config';
import globalize from '../../services/globalize';
import iclarityApiClient from '../../services/iclarityApiClient';

const FORGOTTEN_PASSWORD_URL = `${Config.ICLARITY_API_URL}/api/customers/forgotPassword`;
const REGISTER_USER_URL = `${Config.ICLARITY_API_URL}/api/customers`;

export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, { rejectWithValue }) => {
    try {
        const response = await iclarityApiClient.login(credentials);
        return response;
    } catch (errors) {
        return rejectWithValue(errors);
    }
});

export const forgottenPassword = createAsyncThunk('auth/forgotPassword', async (email, { rejectWithValue }) => {
    try {
        return await iclarityApiClient.post(FORGOTTEN_PASSWORD_URL, { email });
    } catch (errors) {
        return rejectWithValue(errors);
    }
});

export const registerUser = createAsyncThunk('auth/registerUser', async (data, { dispatch, rejectWithValue }) => {
    const { username, password, referralCode, marketingChannelsOptIn } = data;
    try {
        const response = await iclarityApiClient.post(REGISTER_USER_URL, {
            username, password, referralCode, marketingChannelsOptIn,
            email: username, signUpForLoyaltyProgram: true, cultureName: globalize.getCurrentCulture()
        });

        const loginAction = await dispatch(loginUser({ username, password }));
        unwrapResult(loginAction);// unwrap to throw error if any

        return response;
    } catch (errors) {
        return rejectWithValue(errors);
    }
});

const initialState = {
    initializing: true,
    isAuthenticated: false,
    accessToken: localStorage['accessToken'],
    refreshToken: localStorage['refreshToken'],
    passwordResetEmail: ''
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        requestRefreshToken: (state, action) => {
            state.initializing = action.payload.initializing;
        },
        receiveRefreshToken: (state, action) => {
            localStorage['accessToken'] = action.payload.accessToken;
            localStorage['refreshToken'] = action.payload.refreshToken;

            state.initializing = false;
            state.isAuthenticated = true;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        refreshTokenError: (state, action) => {
            state.initializing = false;
            state.isAuthenticated = false;
        },
        logoutUser: (state) => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            state.isAuthenticated = false;
            state.accessToken = '';
            state.refreshToken = '';

            iclarityApiClient.setTokens({ accessToken: '', refreshToken: '' });
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loginUser.fulfilled, (state, action) => {
                const { accessToken, refreshToken } = action.payload;

                localStorage['accessToken'] = accessToken;
                localStorage['refreshToken'] = refreshToken;

                state.isAuthenticated = true;
                state.accessToken = accessToken;
                state.refreshToken = refreshToken;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isAuthenticated = false;
            })
            .addCase(forgottenPassword.pending, (state, action) => {
                state.forgotPasswordSent = false;
            })
            .addCase(forgottenPassword.fulfilled, (state, action) => {
                state.forgotPasswordSent = true;
            })
    }
});

export const {
    requestRefreshToken, receiveRefreshToken, refreshTokenError,
    logoutUser
} = auth.actions;

export default auth.reducer;