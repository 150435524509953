import React from 'react';
import MainLayout from './main';

export const withLayout = (Component, Layout, heightClassName) => (props) => {
    if (!Layout) {
        Layout = MainLayout;
    }

    return (
        <Layout heightClassName={heightClassName || 'vh-lg-100'} >
            <Component {...props} />
        </Layout>
    );
};
