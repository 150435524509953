import React from 'react';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import globalize from '../../services/globalize';
import { Row, Col } from 'react-bootstrap';

const requestErasmusImageFile = require.context('../../assets/images', false, /logosbeneficaireserasmusright_[a-z]{2}_0.jpg$/);

const AuthPageLayout = ({ children, heightClassName }) => {
    const { t } = useTranslation();

    const erasmusImage = requestErasmusImageFile(`./logosbeneficaireserasmusright_${globalize.getCurrentCulture().substring(0, 2)}_0.jpg`).default;

    return (
        <div>
            <Header />
            <main id='content'>
                <div className={`d-flex align-items-center position-relative ${heightClassName}`}>
                    <Col lg={5} xl={4} className={`d-none d-lg-flex align-items-center bg-dark px-0 ${heightClassName}`}>
                        <div className='w-md-80 w-lg-80 text-center mx-auto'>
                            <blockquote className='h3 text-white font-weight-normal mb-4 pb-3'>{t('Auth_Description')}</blockquote>
                        </div>
                        <div className='position-absolute start-0 end-0 bottom-0 text-center p-3'>
                            <Row className='justify-content-center'>
                                <Col lg={12} className='text-center'>
                                    <img className='brand' style={{ maxWidth: '20rem' }} src={erasmusImage} alt='Erasmus+ Logo' />
                                </Col>
                                <Col lg={12} className='text-center'>
                                    <small className='text-muted'>{t('Erasmus_Disclaimer')}</small>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <div className='container'>
                        <Row className='no-gutters'>
                            <Col md={8} lg={7} xl={6} className='offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0'>
                                {children}
                            </Col>
                        </Row>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AuthPageLayout;