import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormText, Form } from 'react-bootstrap';

const Checkbox = ({ horizontal, labelCol = 3, valueCol = 9, input: { name, onChange, checked }, required, label, staticLabel, help, meta: { touched, error, warning, submitError }, ...props }) => {
	const isInvalid = touched && (error || submitError || warning);

	//const errorBlock = isInvalid && <FormText className='invalid-feedback' type='invalid'>{error || submitError || warning}</FormText>;
	const labelHint = required && <em style={{ color: '#D62229' }}>*</em>;
	const controlLabel = () => <span>{label} {labelHint}</span>
	const formControl = <Form.Check id={name} custom label={controlLabel()} checked={!!checked}
		onChange={e => onChange(e.target.checked)}
		isInvalid={isInvalid}
		feedback={error || submitError || warning}
		{...props} />

	const helpBlock = help && <FormText muted>{help}</FormText>;

	if (horizontal) {
		return (
			<FormGroup className='input-label'>
				<Col sm={{ span: valueCol, offset: labelCol }}>
					{formControl}
					{helpBlock}
				</Col>
			</FormGroup>)
	}

	return <FormGroup className='input-label'>
		{formControl}
		{helpBlock}
	</FormGroup>
};

Checkbox.propTypes = {
	horizontal: PropTypes.bool,
	input: PropTypes.object,
	label: PropTypes.string,
	help: PropTypes.string,
	meta: PropTypes.object,
	placeholder: PropTypes.string,
	disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	componentClass: PropTypes.string,
	value: PropTypes.string
};

export default Checkbox;