import React from 'react';
import { Card, NavDropdown } from 'react-bootstrap';
import utils from '../../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import NavDropdownHover from './NavDropdownHover';
import './AccountMenu.css';
import { useTranslation } from 'react-i18next';

const AccountMenu = ({ user, onLogout }) => {
    const { t } = useTranslation();

    const avatarName = user && utils.getAvatarName(user.firstName, user.lastName);
    return <NavDropdownHover id='nav-account' alignRight openOnHover={false} className='account-menu'
        title={<span className='avatar avatar-xs avatar-soft-dark avatar-circle'>
            <span className='avatar-initials'>{avatarName}</span>
        </span>}
    >
        <Card>
            <Card.Header className='p-4'>
                <Link className='media align-items-center' to='/account'>
                    <div className='avatar avatar-sm avatar-soft-dark avatar-circle mr-3'>
                        <span className='avatar-initials'>{avatarName}</span>
                    </div>
                    <div className='media-body'>
                        <span className='d-block font-weight-bold'>{user.firstName} {user.lastName}</span>
                        <span className='d-block small text-muted'>{user.email}</span>
                    </div>
                </Link>
            </Card.Header>
            <Card.Body className='py-3'>
                <Link className='dropdown-item px-0' to='/account/rewards'>
                    <span className='dropdown-item-icon'>
                        <FontAwesomeIcon icon='award' className='mb-0' />
                    </span> {t('Account_RewardHistory')}
                </Link>
                <Link className='dropdown-item px-0' to='/account/points'>
                    <span className='dropdown-item-icon'>
                        <FontAwesomeIcon icon='database' className='mb-0' />
                    </span> {t('Account_PointsHistory')}
                </Link>
                <Link className='dropdown-item px-0' to='/account'>
                    <span className='dropdown-item-icon'>
                        <FontAwesomeIcon icon='user' className='mb-0' />
                    </span> {t('Account_Account')}
                </Link>

                <NavDropdown.Divider />

                <button className='btn btn-link dropdown-item px-0' href='' onClick={onLogout}>
                    <span className='dropdown-item-icon'>
                        <FontAwesomeIcon icon='power-off' className='mb-0' />
                    </span> {t('Account_Logout')}
                </button>
            </Card.Body>
        </Card>
    </NavDropdownHover>;
}

export default AccountMenu;