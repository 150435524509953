import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../components/Dialog';
import Config from '../../config';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InviteCodeDialog = ({ show, friendReferralCode, onCancel }) => {
	const { t } = useTranslation();

	const dialogContent = () => {
		return {
			header: t('InviteCode_Title'),
			body: renderBody()
		};
	};

	const inviteMessage = t('InviteCode_Message', { app_url: Config.APP_URL, invite_code: friendReferralCode });
	const renderBody = () => {
		return <div>
			<p>{t('InviteCode_InfoMessage')}</p>
			<Card className='card-frame card-frame-highlighted'>
				<Card.Body>
					{inviteMessage}
				</Card.Body>
			</Card>
			<div className='d-flex justify-content-end mt-3'>
				<CopyToClipboard variant='primary' className='btn btn-primary mr-2'
					text={inviteMessage}
					onCopy={() => toast.success(t('InviteCode_Copied'))}>
					<button> <FontAwesomeIcon icon='copy' className='mr-1' />{t('InviteCode_Copy')}</button>
				</CopyToClipboard>
				<Button variant='white' onClick={onCancel}>{t('Common_Close')}</Button>
			</div>
			<ToastContainer />
		</div>;
	};

	return <Dialog content={dialogContent()} show={show} onHide={onCancel} />;
}

InviteCodeDialog.propTypes = {
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired
};

export default InviteCodeDialog;