import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withLayout } from '../../layouts';
import { Switch, Route } from 'react-router-dom';
import * as Routes from './routes';
import { fetchCurrentAccount } from '../account/accountSlice';

const Dashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => dispatch(fetchCurrentAccount()), []);

    const routes = Routes.ROUTES;

    return <main>
        <Switch>
            {routes.map((route, index) => <Route key={index} path={route.path} exact={route.exact} component={route.mainContent} />)}
        </Switch>
    </main>
};

export default withLayout(Dashboard);