import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getAvatarName = (firstName, lastName) => {
    if (!firstName && !lastName) {
        return <FontAwesomeIcon icon='user' className='mb-0' />;
    }

    if (firstName && !lastName) {
        return firstName.substr(0, 2).toUpperCase();
    }

    return (firstName[0] + lastName[0]).toUpperCase();
};

const parseDateTime = (text, format) => {
    if (text) {
        if (format) {
            return moment(text, format).toDate();
        } else {
            return new Date(text);
        }
    }
    return new Date();
};

const formatDateTime = (data, format) => {
    if (data) {
        if (moment(data).isValid()) {
            return moment(data).format(format || 'L HH:mm');
        }
        else {
            return data;
        }
    }
    return '';
};

const utils = {
    getAvatarName,
    parseDateTime,
    formatDateTime
};

export default utils;