import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Card, Button, Alert, FormText, Spinner } from 'react-bootstrap';
import { Input, DateTimeInput, Radio, CheckboxGroup } from '../../../components/formControls';
import { useTranslation } from 'react-i18next';
import validators from '../../../services/validators';

const identity = value => (value);  // ensures form value is set to empty string instead of null when cleared, react-final-form ignores nulls

const ProfileForm = ({ onSubmit, initialValues }) => {
    const { t } = useTranslation();
    const genderEntries = [
        { label: t('GenderType_Male'), value: 'Male' },
        { label: t('GenderType_Female'), value: 'Female' },
        { label: t('GenderType_Unknown'), value: 'Unknown' },
    ];
    const marketingChannelEntries = [
        { label: t('MarketingChannel_Email'), value: 'Email' },
        { label: t('MarketingChannel_Mobile'), value: 'Mobile' }
    ];
    return <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ submitError, submitSucceeded, handleSubmit, pristine, submitting }) => (
            <form onSubmit={handleSubmit}>
                <Card>
                    <Card.Header className='mb-3 mb-lg-5'>
                        <h5 className='card-header-title'>{t('PersonalInfo_Title')}</h5>
                    </Card.Header>
                    <Card.Body>
                        {submitError && <Alert variant='danger'>{submitError}</Alert>}
                        {submitSucceeded && <Alert variant='success'>{t('Common_UpdateSuccess')}</Alert>}
                        <Field name='firstName' parse={identity} component={Input} type='text' horizontal label={t('PersonalInfo_FirstName')} placeholder={t('PersonalInfo_FirstName')} />
                        <Field name='lastName' parse={identity} component={Input} type='text' horizontal label={t('PersonalInfo_LastName')} placeholder={t('PersonalInfo_LastName')} />
                        <Field name='dateOfBirth' utc component={DateTimeInput} horizontal label={t('PersonalInfo_DateOfBirth')} placeholder={t('PersonalInfo_DateOfBirth')}
                            validate={validators.dateInThePast} />
                        <Field name='gender' component={Radio} entries={genderEntries} horizontal label={t('PersonalInfo_Gender')} placeholder={t('PersonalInfo_Gender')} />
                        <Field name='mobileTelephone' parse={identity} component={Input} type='text' horizontal label={t('PersonalInfo_MobileTelephone')} placeholder={t('PersonalInfo_MobileTelephone')} />
                        <Field name='town' parse={identity} component={Input} type='text' horizontal label={t('PersonalInfo_Town')} placeholder={t('PersonalInfo_Town')} />
                        <Field name='country' parse={identity} component={Input} type='text' horizontal label={t('PersonalInfo_Country')} placeholder={t('PersonalInfo_Country')} />

                        <Card className='bg-light'>
                            <Card.Body>
                                <p className='input-label'>{t('MarketingConsent_Title')}</p>
                                <FormText muted className='mb-2'>{t('MarketingConsent_Info')}</FormText>
                                <Field name='marketingChannelsOptIn' component={CheckboxGroup} entries={marketingChannelEntries} className='mb-0' />
                            </Card.Body>
                        </Card>
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-end'>
                        <Button type='submit' variant='primary' disabled={pristine || submitting}>
                            {submitting && <Spinner animation='border' size='sm' />} {t('PersonalInfo_Save')}
                        </Button>
                    </Card.Footer>
                </Card>
            </form>
        )}
    </Form>;
};

ProfileForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default ProfileForm;