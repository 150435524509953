import React, { useState } from 'react';
import globalize, { languages } from '../services/globalize';
import { Dropdown } from 'react-bootstrap';
import './LanguageSelector.css';
import { useLocation, useHistory } from 'react-router-dom';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button className='btn btn-link dropdown-nav-link dropdown-toggle d-flex align-items-center p-0'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

const LanguageSelector = ({ onSelect }) => {
    const [currentCulture, setCurrentCulture] = useState(globalize.getCurrentCulture());

    const location = useLocation();
    const history = useHistory();

    const selectedLanguage = languages.find(x => x.culture === currentCulture);

    const selectLanguage = culture => {
        setCurrentCulture(culture);
        if (onSelect) {
            onSelect(culture);
        }
        // globalize.setCurrentCulture(culture);
        const language = languages.find(x => x.culture === culture);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('lang', language.name.toLocaleLowerCase());
        history.replace({ ...location, search: queryParams.toString() });
        history.go();
    }

    return <Dropdown className='language-selector' onSelect={selectLanguage}>
        <Dropdown.Toggle as={CustomToggle}>
            <span>{selectedLanguage.name}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {languages.map(x => <Dropdown.Item key={x.culture} eventKey={x.culture}>
                {x.name}
            </Dropdown.Item>)}
        </Dropdown.Menu>
    </Dropdown>
};

export default LanguageSelector;