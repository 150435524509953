import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button, Alert, Card, FormText, Spinner } from 'react-bootstrap';
import { Input, Checkbox, CheckboxGroup } from '../../components/formControls';
import validators, { composeValidators } from '../../services/validators';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const passwordMinLength = validators.minLength(6);

const RegisterForm = ({ onSubmit, showTermsAndConditions }) => {
    const { t } = useTranslation();

    const marketingChannelEntries = [
        { label: t('MarketingChannel_Email'), value: 'Email' },
        { label: t('MarketingChannel_Mobile'), value: 'Mobile' }
    ];

    const renderTermsAndConditionsLabel = () => {
        return <span>
            {t('Register_AgreeTermsAndConditions')} <Link onClick={() => showTermsAndConditions()}>{t('Register_TermsAndConditions')}</Link>
        </span>
    }
    return (
        <Form onSubmit={onSubmit}>
            {({ submitError, handleSubmit, pristine, submitting, values }) => (
                <form onSubmit={handleSubmit} className='form-login'>
                    {submitError && <Alert variant='danger'>{submitError}</Alert>}
                    <Field name='username' component={Input} type='text' label={t('Register_Email')} placeholder={t('Register_Email')} required validate={composeValidators(validators.required, validators.email)} />
                    <Field name='password' component={Input} type='password'
                        label={t('Register_Password')} placeholder={t('Register_Password')} required validate={composeValidators(validators.required, passwordMinLength)}
                    />
                    <Field name='confirmPassword' component={Input} type='password'
                        label={t('Register_ConfirmPassword')} placeholder={t('Register_ConfirmPassword')} required validate={composeValidators(validators.required, passwordMinLength, validators.equalPassword(values.password))}
                    />
                    <Field name='referralCode' component={Input} type='text' help={t('Register_ReferralCodeInfo')} label={t('Register_ReferralCode')} placeholder={t('Register_ReferralCode')}
                        validate={validators.length(8)} />
                    <Field name='agreeTermsAndConditions' component={Checkbox} label={renderTermsAndConditionsLabel()} type='checkbox' required validate={validators.requiredCheckbox} />
                    <Card className='bg-light'>
                        <Card.Body>
                            <p className='input-label'>{t('MarketingConsent_Title')}</p>
                            <FormText muted className='mb-2'>{t('MarketingConsent_Info')}</FormText>
                            <Field className='mb-0' name='marketingChannelsOptIn' component={CheckboxGroup} entries={marketingChannelEntries} />
                        </Card.Body>
                    </Card>
                    <Row className='align-items-center mb-5 mt-2'>
                        <Col sm={8} className='mb-3 mb-sm-0'>
                            <span className='font-size-1 text-muted'>{t('Register_HasAccount')} </span>
                            <Link to='/login' className='font-size-1 font-weight-bold'>{t('Register_Login')}</Link>
                        </Col>
                        <Col sm={4} className='text-sm-right'>
                            <Button type='submit' disabled={pristine || submitting} className='btn btn-primary transition-3d-hover'>
                                {submitting && <Spinner animation='border' size='sm' />} {t('Register_Register')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Form>
    );
};

RegisterForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default RegisterForm;