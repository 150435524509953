import { i18n } from './globalize';
import moment from 'moment';

const required = value => (value || value === '0') ? undefined : i18n.t('Validator_Required');

const requiredArray = value => (value && value.length > 0) ? undefined : i18n.t('Validator_Required');

const requiredCheckbox = value => (value && value === true) ? undefined : i18n.t('Validator_Required');

const length = length => value =>
	value && value.length !== length ? i18n.t('Validator_Length', { length }) : undefined;

const minLength = min => value =>
	value && value.length < min ? i18n.t('Validator_MinLength', { min }) : undefined;

const maxLength = max => value =>
	value && value.length > max ? i18n.t('Validator_MaxLength', { max }) : undefined;

const number = value => value && isNaN(Number(value)) ? i18n.t('Validator_Number') : undefined;

const minValue = min => value =>
	value && value < min ? i18n.t('Validator_MinValue', { min }) : undefined;

const maxValue = max => value =>
	value && value > max ? i18n.t('Validator_MaxValue', { max }) : undefined;

const range = (min, max) => value =>
	value && (value < min || value > max) ? i18n.t('Validator_Range', { min, max }) : undefined;

const pattern = (regex, messageI18nKey) => value =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
		i18n.t(messageI18nKey || 'Validator_Pattern') : undefined;

const email = pattern(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Validator_Email');

const dateInThePast = value => value && (moment.isDate(value) || moment.isMoment(value)) && moment(value).isAfter() ? i18n.t('Validator_DateInThePast') : undefined;

const equalPassword = equalTo => value => equalTo !== value ? i18n.t('Validator_EqualPassword') : undefined

const validators = {
	required,
	requiredArray,
	requiredCheckbox,
	minLength,
	maxLength,
	length,
	number,
	minValue,
	maxValue,
	range,
	pattern,
	email,
	dateInThePast,
	equalPassword
};

export default validators;

export const composeValidators = (...validators) => value =>
	validators.reduce((error, validator) => error || validator(value), undefined);
