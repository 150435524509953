import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const ActivityListItem = ({ activity }) => {
    const { t } = useTranslation();
    const { store, retailer } = activity;
    const storeName = store ? store.name : retailer ? retailer.name : null;

    return <Card className='mb-5'>
        <Row>
            <Col lg={6}>
                <Link to={`/activities/${activity.id}`}>
                    <Card.Img variant='left' src={activity.imageUrl} className='img-fluid rounded-lg'></Card.Img>
                </Link>
            </Col>
            <Col lg={6}>
                <Card.Body className='d-flex flex-column h-100'>
                    <div><span className='badge badge-pill badge-success text-uppercase mb-2'>{t(`AwardType_${activity.awardType}`)}</span></div>
                    <Link to={`/activities/${activity.id}`}>
                        <h3>{activity.name}</h3>
                    </Link>
                    {/* <p dangerouslySetInnerHTML={{ __html: activity.description }} /> */}
                    <div className='media align-items-center mt-auto'>
                        <div className='media-body'>
                            {storeName && <span className='text-dark'>
                                <span className='d-inline-block text-inherit font-weight-bold'>
                                    <FontAwesomeIcon icon='map-marker-alt' className='mb-0' /> <span>{storeName}</span>
                                </span>
                            </span>}
                            <small className='d-block'>{moment(activity.startDate).format('L')}</small>
                        </div>
                    </div>
                </Card.Body>
            </Col>
        </Row>
    </Card >;
};

export default ActivityListItem;