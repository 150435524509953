import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button, Alert, Spinner } from 'react-bootstrap';
import { Input } from '../../components/formControls';
import validators, { composeValidators } from '../../services/validators';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ForgottenPasswordForm = ({ onSubmit }) => {
    const { t } = useTranslation();
    return (
        <Form onSubmit={onSubmit}>
            {({ submitError, handleSubmit, pristine, submitting }) => (
                <form onSubmit={handleSubmit} className='form-login'>
                    {submitError && <Alert variant='danger'>{submitError}</Alert>}
                    <Field name='email' component={Input} type='text'
                        label={t('ForgottenPassword_Email')}
                        placeholder={t('ForgottenPassword_Email')} validate={composeValidators(validators.required, validators.email)}
                    />
                    <Row className='align-items-center mb-5'>
                        <Col sm={6} className='mb-3 mb-sm-0'>
                            <Link to={`/login`} className='font-size-1 font-weight-bold'>
                                <FontAwesomeIcon icon='angle-left' className='mr-1 back-icon' /> {t('ForgottenPassword_Login')}</Link>
                        </Col>
                        <Col sm={6} className='text-sm-right'>
                            <Button type='submit' disabled={pristine || submitting} className='btn btn-primary transition-3d-hover'>
                                {submitting && <Spinner animation='border' size='sm' />} {t('ForgottenPassword_ForgottenPassword')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Form>
    );
};

ForgottenPasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default ForgottenPasswordForm;