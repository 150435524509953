import fetch from 'isomorphic-fetch';
import moment from 'moment';
import Config from '../config';
import globalize, { i18n } from './globalize';
import { FORM_ERROR } from 'final-form';

export const API_ROOT = Config.ICLARITY_API_URL;
export const API_KEY = Config.ICLARITY_API_KEY;

const TOKEN_URL = `${Config.ICLARITY_API_URL}/token`;

export const getDefaultRequestHeaders = () => ({
    'Content-Type': 'application/json',
    'X-iClarity-Api-Key': API_KEY,
    'X-Accept-Language': globalize.getCurrentCulture()
});

let onRequestRefreshToken = null;
let onRequestRefreshTokenSuccess = null;
let onRequestRefreshTokenFailure = null;

const init = options => {
    if (options) {
        if (options.onRequestRefreshToken && typeof options.onRequestRefreshToken === 'function') {
            onRequestRefreshToken = options.onRequestRefreshToken;
        }
        if (options.onRequestRefreshTokenSuccess && typeof options.onRequestRefreshTokenSuccess === 'function') {
            onRequestRefreshTokenSuccess = options.onRequestRefreshTokenSuccess;
        }
        if (options.onRequestRefreshTokenFailure && typeof options.onRequestRefreshTokenFailure === 'function') {
            onRequestRefreshTokenFailure = options.onRequestRefreshTokenFailure;
        }
    }
}

let currentAccessToken = '';
let currentRefreshToken = '';

const setTokens = ({ accessToken, refreshToken }) => {
    currentAccessToken = accessToken;
    currentRefreshToken = refreshToken;
}

const makeRequest = async (method, endpoint, data, isRetry) => {
    let body = undefined;
    if (method === 'GET') {
        const queryParams = encodeQueryParams(data);
        endpoint = `${endpoint}?${queryParams}`;
    } else {
        if (data) {
            body = JSON.stringify(data, (key, value) => {
                var initialValue = data[key];
                if (initialValue instanceof Date) {
                    // call format to include timezone info - "2015-09-16T00:00:00+01:00" (moment().toJSON() or default behaviour 
                    // of JSON.stringify returns UTC time)
                    return moment(initialValue).format();
                }
                return value;
            });
        }
    }

    const fullUrl = endpoint.indexOf(API_ROOT) === -1 ? `${API_ROOT}/${endpoint}` : endpoint;

    const options = {
        method,
        headers: getDefaultRequestHeaders(),
        body
    };

    if (currentAccessToken) {
        options.headers['Authorization'] = `Bearer ${currentAccessToken}`;
    }

    let json;
    try {
        const response = await fetch(fullUrl, options);

        if (response.headers.get('Content-Type') && response.headers.get('Content-Type').includes('text/html')) {
            return response.text().then(text => {
                if (!response.ok) {
                    if (response.status === 401 && !isRetry) {
                        // refresh access token and retry current API call
                        return refreshToken(false)
                            .then(async () => await makeRequest(method, endpoint, data, true));
                    }
                    else {
                        throw text;
                    }
                }
                return text;
            });
        }
        else {
            try {
                json = await response.json();
            } catch (jsonError) { }

            if (!response.ok) {
                if (response.status === 401 && !isRetry) {
                    // refresh access token and retry current API call
                    return refreshToken(false)
                        .then(async () => await makeRequest(method, endpoint, data, true));
                }
                else {
                    throw json;
                }
            }

            return json;
        }
    }
    catch (error) {
        const errors = processErrorResponse(error);
        throw errors;
    }
};

const encodeQueryParams = (params) => {
    let queryString = '';

    if (params) {
        queryString = Object.keys(params)
            .flatMap(k => {
                const paramValue = params[k];
                let values = [paramValue];
                if (Array.isArray(paramValue)) {
                    values = [...paramValue];
                }
                return values.map(value => {
                    if (value instanceof Date) {
                        value = moment(value).format();
                    }

                    return value !== null && value !== undefined && value !== '' ? encodeURIComponent(k) + '=' + encodeURIComponent(value) : null;
                });
            })
            .filter(queryParam => queryParam !== null && queryParam !== undefined)
            .join('&');
    }

    return queryString;
};

const login = async (credentials) => {
    const { username, password } = credentials;
    const defaultHeaders = getDefaultRequestHeaders();
    const config = {
        method: 'POST',
        headers: {
            ...defaultHeaders,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&client_id=${encodeURIComponent(Config.ICLARITY_API_KEY)}`
    };

    let json;
    try {
        const response = await fetch(TOKEN_URL, config);
        json = await response.json();

        if (!response.ok) {
            const errorMessage = json.error_description ? json.error_description : json.error;
            throw new Error(errorMessage);
        } else {
            currentAccessToken = json.access_token;
            currentRefreshToken = json.refresh_token;

            return {
                accessToken: json.access_token,
                refreshToken: json.refresh_token
            };
        }
    } catch (error) {
        const errors = processErrorResponse(error);
        throw errors;
    }
};

let isRefreshingToken = false;
let refreshTokenPromise = null;

const refreshToken = initializing => {
    if (!isRefreshingToken) {
        if (!currentRefreshToken) {
            const errorMessage = 'Refresh token is missing';
            if (onRequestRefreshTokenFailure) {
                onRequestRefreshTokenFailure(errorMessage);
            }
            return Promise.reject(errorMessage);
        }

        isRefreshingToken = true;

        const defaultHeaders = getDefaultRequestHeaders();

        const config = {
            method: 'POST',
            headers: {
                ...defaultHeaders,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `grant_type=refresh_token&refresh_token=${encodeURIComponent(currentRefreshToken)}&client_id=${encodeURIComponent(Config.ICLARITY_API_KEY)}`
        };

        if (onRequestRefreshToken) {
            onRequestRefreshToken(initializing);
        }

        refreshTokenPromise = fetch(TOKEN_URL, config)
            .then(async response => {
                isRefreshingToken = false;

                try {
                    const json = await response.json();
                    if (!response.ok) {
                        const errorMessage = json.error_description ? json.error_description : json.error;
                        if (onRequestRefreshTokenFailure) {
                            onRequestRefreshTokenFailure(errorMessage);
                        }
                        throw new Error(errorMessage);
                    } else {
                        const { access_token: accessToken, refresh_token: refreshToken } = json;

                        currentAccessToken = accessToken;
                        currentRefreshToken = refreshToken;

                        if (onRequestRefreshTokenSuccess) {
                            onRequestRefreshTokenSuccess({ accessToken, refreshToken });
                        }
                        return { accessToken, refreshToken };
                    }
                } catch (error) {
                    return Promise.reject(processErrorResponse(error));
                }
            }).catch((error) => {
                isRefreshingToken = false;
                return Promise.reject(processErrorResponse(error));
            });
    }

    return refreshTokenPromise;
};

export const processErrorResponse = (errorResponse) => {
    if (typeof errorResponse === 'string') {
        return {
            [FORM_ERROR]: errorResponse,
            validationErrorMessage: errorResponse
        };
    }

    const { message, modelState } = errorResponse;
    const validationErrors = {};

    let validationErrorMessage = '';

    if (modelState) {
        Object.keys(modelState).forEach(key => {
            const dotPosition = key.lastIndexOf('.');
            let propName = key.substring(dotPosition + 1);
            propName = propName.charAt(0).toLowerCase() + propName.slice(1);

            validationErrors[propName] = modelState[key][0];
        });
        validationErrorMessage = Object.values(validationErrors)[0]
    }

    const formErrorMessage = message === "The request is invalid." ? i18n.t('BadRequest_Message') : message;

    return {
        [FORM_ERROR]: formErrorMessage,
        validationErrorMessage,//first validation error
        ...validationErrors
    };
};

const iclarityApiClient = {
    init,
    login,
    refreshToken,
    setTokens,

    makeRequest,
    get: async (endpoint, data) => await makeRequest('GET', endpoint, data),
    post: async (endpoint, data) => await makeRequest('POST', endpoint, data),
    put: async (endpoint, data) => await makeRequest('PUT', endpoint, data),
    delete: async (endpoint, data) => await makeRequest('DELETE', endpoint, data),
};

export default iclarityApiClient;