import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector
} from '@reduxjs/toolkit'
import iclarityApiClient from '../../services/iclarityApiClient'

const rewardsAdapter = createEntityAdapter();

const initialState = rewardsAdapter.getInitialState({
    loading: false,
    pagination: {
        sortKey: '',
        sortDirection: '',
        pageSize: 10,
        pageNumber: 1,
        totalPages: 0,
        totalCount: 0,
        hasNext: false,
        hasPrevious: false,
        ids: []
    },
    ui: {}
})

const initialRewardUiState = {
    redeemRequestStatus: 'idle',
    redeemRequestError: null
};

// Thunk functions
export const loadRewards = createAsyncThunk('rewards/loadRewards', async (options) => {
    const { ...pagination } = options;
    const response = await iclarityApiClient.get('api/loyaltyMember/rewards', { ...pagination });
    return response;
});

export const redeemReward = createAsyncThunk('rewards/redeemReward', async (id, { rejectWithValue }) => {
    try {
        const response = await iclarityApiClient.put(`api/loyaltyMember/rewards/${id}`);
        return response;
    } catch (errors) {
        return rejectWithValue(errors); // errors are set as action.payload and can be used in catch after unwrapResult
    }
});

const rewardsSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(loadRewards.pending, (state, action) => {
                const { sortKey, sortDirection, pageSize, pageNumber } = action.meta.arg;
                state.loading = true;
                state.pagination.sortKey = sortKey;
                state.pagination.sortDirection = sortDirection;
                state.pagination.pageSize = pageSize;
                state.pagination.pageNumber = pageNumber;
            })
            .addCase(loadRewards.fulfilled, (state, action) => {
                const { data, totalCount, totalPages, hasNext, hasPrevious } = action.payload;
                rewardsAdapter.upsertMany(state, data);
                state.loading = false;
                state.pagination.totalPages = totalPages;
                state.pagination.totalCount = totalCount;
                state.pagination.hasNext = hasNext;
                state.pagination.hasPrevious = hasPrevious;
                state.pagination.ids = data.map(entity => entity.id);
            })
            .addCase(loadRewards.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(redeemReward.pending, (state, action) => {
                updateRewardUiState(state, action.meta.arg, { redeemRequestStatus: 'loading', redeemRequestError: null });
            })
            .addCase(redeemReward.fulfilled, (state, action) => {
                updateRewardUiState(state, action.meta.arg, { redeemRequestStatus: 'succeeded' });
            })
            .addCase(redeemReward.rejected, (state, action) => {
                updateRewardUiState(state, action.meta.arg, { redeemRequestStatus: 'failed', redeemRequestError: action.payload.validationErrorMessage });
            })
    }
});

const updateRewardUiState = (state, rewardId, newState) => {
    const currentRewardState = state.ui[rewardId] || initialRewardUiState;
    state.ui[rewardId] = { ...currentRewardState, ...newState };
}

export default rewardsSlice.reducer;

export const {
    selectAll: selectAllRewards
} = rewardsAdapter.getSelectors(state => state.rewards);

export const selectPagedRewards = createSelector(
    state => state.rewards.entities,
    state => state.rewards.pagination,
    (entities, pagination) => pagination.ids.map(id => entities[id])
);

export const selectRewardUiStateById = (state, id) => state.rewards.ui[id] || initialRewardUiState;