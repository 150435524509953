import React from 'react';
import { Card, Collapse } from 'react-bootstrap';
import utils from '../../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IndexLinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';

const Sidebar = ({ user, showNavigation, onLogout }) => {
    const { t } = useTranslation();
    return <div className='navbar-expand-lg navbar-expand-lg-collapse-block navbar-light'>
        <Collapse id='sidebarNav' className='navbar-collapse navbar-vertical' in={showNavigation}>
            <div>
                <Card className='mb-5'>
                    <Card.Body>
                        {/* Avatar */}
                        {user && <div className='d-none d-lg-block text-center mb-5'>
                            <div className='avatar avatar-xxl avatar-circle avatar-soft-dark mb-3'>
                                <span className='avatar-initials'>{utils.getAvatarName(user.firstName, user.lastName)}</span>
                            </div>

                            <h4 className='card-title'>{user.firstName} {user.lastName}</h4>
                            <p className='card-text font-size-1'>{user.email}</p>
                        </div>}
                        {/* End Avatar */}

                        {/* Nav */}

                        <h6 className='text-cap small'>{t("Account_Account")}</h6>
                        {/* List */}
                        <ul className='nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4'>
                            <li className='nav-item'>
                                <IndexLinkContainer className='nav-link' role='button' to='/account'>
                                    <span><FontAwesomeIcon icon='id-card' className='nav-icon mb-0' /> {t("PersonalInfo_Title")}</span>
                                </IndexLinkContainer>
                            </li>
                            <li className='nav-item'>
                                <IndexLinkContainer className='nav-link' role='button' to='/account/login-and-security'>
                                    <span><FontAwesomeIcon icon='shield-alt' className='nav-icon mb-0' /> {t("Account_LoginAndSecurity")}</span>
                                </IndexLinkContainer>
                            </li>
                        </ul>
                        {/* End List */}

                        <h6 className='text-cap small'>{t("Account_Loyalty")}</h6>
                        {/* List */}
                        <ul className='nav nav-sub nav-sm nav-tabs nav-list-y-2 mb-4'>
                            <li className='nav-item'>
                                <IndexLinkContainer className='nav-link' role='button' to='/account/rewards'>
                                    <span><FontAwesomeIcon icon='award' className='nav-icon mb-0' />{t('Account_Rewards')}</span>
                                </IndexLinkContainer>
                            </li>
                            <li className='nav-item'>
                                <IndexLinkContainer className='nav-link' role='button' to='/account/points'>
                                    <span><FontAwesomeIcon icon='database' className='nav-icon mb-0' />{t('Account_Points')}</span>
                                </IndexLinkContainer>
                            </li>
                        </ul>
                        {/* End List */}

                        <div className='d-lg-none'>
                            <div className='dropdown-divider'></div>

                            {/* List */}
                            <ul className='nav nav-sub nav-sm nav-tabs nav-list-y-2'>
                                <li className='nav-item'>
                                    <button className='btn btn-link nav-link text-primary' href='#' onClick={onLogout}>
                                        <FontAwesomeIcon icon='sign-out-alt' className='nav-icon mb-0' /> {t("Account_Logout")}
                                    </button>
                                </li>
                            </ul>
                            {/* End List */}
                        </div>
                        {/* End Nav */}
                    </Card.Body>
                </Card>
            </div>
        </Collapse>
    </div>;
};

export default Sidebar;