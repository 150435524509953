import React, { useEffect, useState } from 'react';
import globalize from '../../services/globalize';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoImage from '../../assets/images/logo.png';
import { useTranslation } from 'react-i18next';

const requestErasmusImageFile = require.context('../../assets/images', false, /logosbeneficaireserasmusright_[a-z]{2}_0.jpg$/);

const Footer = () => {
    const { t } = useTranslation();

    const erasmusImage = requestErasmusImageFile(`./logosbeneficaireserasmusright_${globalize.getCurrentCulture().substring(0, 2)}_0.jpg`).default;

    return <footer className='bg-dark'>
        <div className='container'>
            <Row className='space-top-2 space-bottom-1 space-bottom-lg-2 justify-content-lg-between align-items-lg-center text-center text-lg-left'>
                <Col lg={5} className='mb-3 mb-lg-0'>
                    <img className='brand' style={{ maxWidth: '20rem' }} src={erasmusImage} alt='Erasmus+ Logo' />
                </Col>
                <Col lg={7} className='text-lg-right'>
                    <small className='text-muted'>{t('Erasmus_Disclaimer')}</small>
                </Col>
            </Row>

            <hr className='opacity-xs my-0' />

            <Row className='space-1 align-items-md-center'>
                <Col md={3} className='mb-4 mb-md-0'>
                    <Link to='/'>
                        <img className='brand' src={LogoImage} alt='Logo' />
                    </Link>
                </Col>

                <Col sm={7} md={6} className='mb-4 mb-sm-0 text-center'>
                    <div className='mb-4'>
                        <h5 className='text-white'>{t('App_Download')}</h5>
                    </div>

                    <a className='btn btn-icon btn-soft-light rounded-circle mr-2' target='_blank' href='https://apps.apple.com/bg/app/happiness-accelerator/id1453004886'>
                        <FontAwesomeIcon icon={['fab', 'apple']} />
                    </a>
                    <a className='btn btn-icon btn-soft-light rounded-circle' target='_blank' href='https://play.google.com/store/apps/details?id=net.loyalty.happiness'>
                        <FontAwesomeIcon icon={['fab', 'google-play']} />
                    </a>
                </Col>

                <Col sm={5} md={3} className='text-sm-right'>
                    {/* Social Networks */}
                    <ul className='list-inline mb-0'>
                        <li className='list-inline-item mb-2 mb-sm-0'>
                            <a className='btn btn-icon btn-xs btn-soft-light rounded-circle' target='_blank' href='https://www.facebook.com/Happinessaccelerator.eu/'>
                                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                            </a>
                        </li>
                        <li className='list-inline-item mb-2 mb-sm-0'>
                            <a className='btn btn-icon btn-xs btn-soft-light rounded-circle' target='_blank' href='https://bg.linkedin.com/company/happinessacademy'>
                                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                            </a>
                        </li>
                    </ul>
                    {/* End Social Networks */}
                </Col>
            </Row>
        </div>
    </footer>;
}

export default Footer;