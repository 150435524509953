import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import iclarityApiClient from '../../services/iclarityApiClient';
import moment from 'moment';

const initialState = {
    id: 0,
    pointsBalance: 0,
    rewards: []
};

// Thunk functions
export const fetchCurrentAccount = createAsyncThunk('account/fetchCurrentAccount',
    async () => await iclarityApiClient.get('api/loyaltyMember')
);

export const changePassword = createAsyncThunk('account/changePassword', async (data, { rejectWithValue }) => {
    try {
        return await iclarityApiClient.put('api/customers/changePassword', data);
    } catch (errors) {
        return rejectWithValue(errors);
    }
});

export const updatePersonalDetails = createAsyncThunk('account/updatePersonalDetails', async (data, { rejectWithValue }) => {
    try {
        if (data.dateOfBirth === undefined) {
            data.dateOfBirth = null; // clear birth date if not entered
        }

        const responseData = await iclarityApiClient.put('api/customers', data);
        return responseData;
    } catch (errors) {
        return rejectWithValue(errors);  // return errors in react-final-form format
    }
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCurrentAccount.fulfilled, (state, action) => updateAccountState(state, action))
            .addCase(updatePersonalDetails.fulfilled, (state, action) => updateAccountState(state, action))
    }
});

const updateAccountState = (state, action) => {
    let account = action.payload;
    if (account && account.dateOfBirth) {
        account.dateOfBirth = moment.utc(account.dateOfBirth).format();
    }
    return { ...state, ...account };
}

export default accountSlice.reducer;

export const selectCurrentAccount = state => state.account;
