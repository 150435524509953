import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReduxBlockUi from 'react-block-ui/redux';
import 'react-block-ui/style.css';

class Dialog extends Component {
	renderFooter() {
		const { footer } = this.props.content;

		return footer &&
			<Modal.Footer>
				{footer}
			</Modal.Footer>;
	}

	renderHeader() {
		const { header } = this.props.content;
		return (
			<Modal.Header closeButton>
				<Modal.Title>
					{header}
				</Modal.Title>
			</Modal.Header>
		);
	}

	renderBody() {
		const { body } = this.props.content;
		return (
			<Modal.Body>
				{body}
			</Modal.Body>
		);
	}

	render() {
		const { show, onHide, block, unblock, ...rest } = this.props;
		return <Modal
			{...rest}
			show={show}
			onHide={onHide}>
			<ReduxBlockUi block={block} unblock={unblock}>
				{this.renderHeader()}
				{this.renderBody()}
				{this.renderFooter()}
			</ReduxBlockUi>
		</Modal>;
	}
}

Dialog.propTypes = {
	content: PropTypes.shape({
		header: PropTypes.node.isRequired,
		body: PropTypes.node.isRequired,
		footer: PropTypes.node
	}),
	show: PropTypes.bool,
	onHide: PropTypes.func.isRequired
};

export default Dialog;