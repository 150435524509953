import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCmsPage } from './cmsPagesSlice';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BlockUi from 'react-block-ui';

const CmsPageDetails = ({ match }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const pageSlug = match.params.slug;

    useEffect(() => dispatch(fetchCmsPage(pageSlug)), []);

    const cmsPage = useSelector(state => state.cmsPages[pageSlug]);

    return (
        <Container className='space-1 space-bottom-lg-3'>
            <h2>{t(`CmsPage_${pageSlug.toLowerCase()}_Title`)}</h2>
            {cmsPage &&
                <BlockUi blocking={cmsPage.loading}>
                    <div dangerouslySetInnerHTML={{ __html: cmsPage.content }} />
                    {cmsPage.error && <p>{t('CmsPage_NotFound')}</p>}
                </BlockUi>
            }
        </Container>
    );
};

export default CmsPageDetails;