import React from 'react';
import { Button } from 'react-bootstrap';
import Dialog from './Dialog';
import { useTranslation } from 'react-i18next';

const InformationMessage = ({ title, message, onClose, ...rest }) => {
	const { t } = useTranslation();
	const footer = (
		<div>
			<Button variant='white' onClick={onClose}>{t('Common_Close')}</Button>
		</div>
	);
	return <Dialog content={{ header: title, body: message, footer: footer }} onHide={onClose} {...rest} />;
};

export default InformationMessage;