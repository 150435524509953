import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Nav } from 'react-bootstrap';
import { loadRewards, selectPagedRewards } from './rewardsSlice';
import Pagination from '../../components/Pagination';
import { useTranslation } from 'react-i18next';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import RewardListItem from './RewardListItem';

const RewardsList = ({ }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const loading = useSelector(state => state.rewards.loading);
    const pagination = useSelector(state => state.rewards.pagination);
    const rewards = useSelector(selectPagedRewards);
    const [onlyActive, setOnlyActive] = useState(true);

    useEffect(() => {
        const { sortKey, sortDirection, pageNumber, pageSize } = pagination;
        dispatch(loadRewards({ onlyActive, sortKey, sortDirection, pageSize, pageNumber }));
    }, []);

    const loadRewardsByStatus = active => {
        setOnlyActive(active);
        const { sortKey, sortDirection, pageSize } = pagination;
        dispatch(loadRewards({ onlyActive: active, sortKey, sortDirection, pageSize, pageNumber: 1 }));
    };

    const handlePageSelect = selectedPageNumber => {
        // search with new page number
        const { sortKey, sortDirection, pageSize } = pagination;
        dispatch(loadRewards({ sortKey, sortDirection, pageSize, pageNumber: selectedPageNumber }));
    };

    return <Card>
        <Card.Header>
            <h5 className='card-header-title'>{t("Rewards_History")}</h5>
        </Card.Header>
        <Card.Body>
            <Nav fill className='nav-segment mb-5'>
                <Nav.Item>
                    <a className={`nav-link ${onlyActive ? 'active' : ''}`} onClick={() => loadRewardsByStatus(true)} href='#active-rewards'>{t('Reward_ActiveRewards')}</a>
                </Nav.Item>
                <Nav.Item>
                    <a className={`nav-link ${!onlyActive ? 'active' : ''}`} onClick={() => loadRewardsByStatus(false)} href='#all-rewards'>{t('Reward_AllRewards')}</a>
                </Nav.Item>
            </Nav>
            <BlockUi blocking={loading}>
                {!loading && rewards.length === 0 && <p className='text-center'>{onlyActive ? t('Rewards_OnlyActive_None') : t('Rewards_All_None')}</p>}
                {rewards.map(item => <RewardListItem key={item.id} item={item} />)}
                <div className='d-flex justify-content-end mt-5'>
                    {pagination.totalCount > 0 && <Pagination hideFirstAndLastPageLinks
                        totalPages={pagination.totalPages}
                        currentPage={pagination.pageNumber}
                        onChange={handlePageSelect} />}
                </div>
            </BlockUi>
        </Card.Body>
    </Card>
};

export default RewardsList;