import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import PackageJson from '../../package.json';

// Load only required locales
import 'moment/locale/en-gb';
import 'moment/locale/bg';
import 'moment/locale/de';
import 'moment/locale/es';

const init = () => {
    const currentCulture = getCurrentCulture();

    // init i18next
    i18n.changeLanguage(currentCulture, (error, t) => {
    });

    // localize moment
    moment.locale(currentCulture);
};

const getSelectedCulture = () => {
    return localStorage.getItem('i18n_lng');
};

const getCurrentCulture = () => {
    var currentCulture = getSelectedCulture();
    if (!currentCulture) {
        currentCulture = 'en-GB';
    }
    return currentCulture;
};

const setCurrentCulture = (lang) => {
    const selectedLanguage = languages.find(x => x.culture.toLowerCase() === lang.toLowerCase() || x.name.toLowerCase() === lang.toLowerCase());
    if (selectedLanguage) {
        localStorage.setItem('i18n_lng', selectedLanguage.culture);
        init();
    }
};

const globalize = {
    init,
    getSelectedCulture,
    getCurrentCulture,
    setCurrentCulture
};

export default globalize;

const languages = [
    { culture: 'en-GB', name: 'EN' },
    { culture: 'bg-BG', name: 'BG' },
    { culture: 'de-DE', name: 'DE' },
    { culture: 'es-ES', name: 'ES' }
];

i18n
    .use(XHR)
    .use(initReactI18next)
    .init({
        whitelist: languages.map(x => x.culture), // Specify array of allowed languages
        fallbackLng: 'en-GB',
        lng: getCurrentCulture(),
        ns: ['resources'],
        defaultNS: 'resources',
        interpolation: {
            escapeValue: false, // not needed for react
            formatSeparator: ',',
            format: function (value, format, lng) {
                if (format === 'uppercase') return value.toUpperCase();
                return value;
            }
        },
        backend: {
            loadPath: (lng, ns) => {
                return `/locales/{{lng}}/{{ns}}.json?v=${PackageJson.version}`;
            }
        }
        //,debug: true		// use debug info for missing keys to generate replacement tags for <Trans> component
    });

export {
    i18n,
    languages
};