import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button, Alert, Spinner } from 'react-bootstrap';
import { Input } from '../../components/formControls';
import validators from '../../services/validators';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginForm = ({ onSubmit }) => {
    const { t } = useTranslation();
    return (
        <Form onSubmit={onSubmit}>
            {({ submitError, handleSubmit, pristine, submitting }) => (
                <form onSubmit={handleSubmit} className='form-login'>
                    {submitError && <Alert variant='danger'>{submitError}</Alert>}
                    <Field name='username' component={Input} type='text' label={t('Login_Email')} placeholder={t('Login_Email')} validate={validators.required} />
                    <Field name='password' component={Input} type='password'
                        label={<span>{t('Login_Password')}<Link to={`/forgottenPassword`} className='link-underline float-right'>{t('Login_ForgotPassword')}</Link></span>}
                        placeholder={t('Login_Password')} validate={validators.required}
                    />
                    <Row className='align-items-center mb-5'>
                        <Col sm={8} className='mb-3 mb-sm-0'>
                            <span className='font-size-1 text-muted'>{t('Login_NoAccount')} </span>
                            <Link to='/register' className='font-size-1 font-weight-bold'>{t('Login_Register')}</Link>
                        </Col>
                        <Col sm={4} className='text-sm-right'>
                            <Button type='submit' disabled={pristine || submitting} className='btn btn-primary transition-3d-hover'>
                                {submitting && <Spinner animation='border' size='sm' />} {t('Login_Login')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
        </Form>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default LoginForm;