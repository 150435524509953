import PersonalInfoPage from './personalInfo/PersonalInfoPage';
import SecurityPage from './security/SecurityPage';
import RewardsList from '../rewards/RewardsList';
import PointsList from '../points/PointsList';

export const ROUTES = [
	{
		path: '/account',
		exact: true,
		pageTitleResourceKey: 'Account_PersonalInfo_Title',
		mainContent: PersonalInfoPage
	},
	{
		path: '/account/login-and-security',
		exact: true,
		pageTitleResourceKey: 'Account_Security_Title',
		mainContent: SecurityPage
	},
	{
		path: '/account/rewards',
		exact: true,
		pageTitleResourceKey: 'Account_Rewards_Title',
		mainContent: RewardsList
	},
	{
		path: '/account/points',
		exact: true,
		pageTitleResourceKey: 'Account_Points_Title',
		mainContent: PointsList
	}
];
