import React from 'react';
import { Button } from 'react-bootstrap';
import Dialog from './Dialog';
import { useTranslation } from 'react-i18next';

const ConfirmationMessage = ({ title, message, onConfirm, onCancel, ...rest }) => {
	const { t } = useTranslation();
	const footer = (
		<div>
			<Button bsStyle='primary' className='mr-2' onClick={onConfirm}>{t('Common_Yes')}</Button>
			<Button variant='white' onClick={onCancel}>{t('Common_No')}</Button>
		</div>
	);
	return <Dialog content={{ header: title, body: message, footer: footer }} onHide={onCancel} {...rest} />;
};

export default ConfirmationMessage;