import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import rootReducer from './rootReducer';

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    const customMiddleware = [];
    if (process.env.NODE_ENV !== 'production') {
      customMiddleware.push(logger);
    }
    return getDefaultMiddleware().concat(customMiddleware);
  }
});