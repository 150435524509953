import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector
} from '@reduxjs/toolkit'
import config from '../../config';
import iclarityApiClient from '../../services/iclarityApiClient';

const activitiesAdapter = createEntityAdapter();

const initialState = activitiesAdapter.getInitialState({
    loading: false,
    filter: { query: '', categoryIds: [] },
    pagination: {
        sortKey: '',
        sortDirection: '',
        pageSize: 10,
        pageNumber: 1,
        totalPages: 0,
        totalCount: 0,
        hasNext: false,
        hasPrevious: false,
        ids: []
    },
    ui: {}  // keep UI required state per activity ID
});

const initialActivityUiState = {
    fetchRequestStatus: 'idle',
    redeemRequestStatus: 'idle',
    redeemRequestError: null,
    eventRequestStatus: 'idle',
    eventRequestError: null
};

// Thunk functions
export const loadActivities = createAsyncThunk('activities/loadActivities', async (options) => {
    const { query, categoryIds, ...pagination } = options;
    const response = await iclarityApiClient.get('api/loyaltyMember/offers', {
        query,
        onlyNotReachLimit: true,
        labelIds: categoryIds && categoryIds.length > 0 ? categoryIds : config.VISIBLE_CATEGORIES,
        ...pagination
    });
    return response;
});

export const fetchActivity = createAsyncThunk('activities/fetchActivity',
    async (id) => await iclarityApiClient.get(`api/loyaltyMember/offers/${id}`));

export const redeemActivity = createAsyncThunk('activities/redeemActivity', async (id, { rejectWithValue }) => {
    try {
        const response = await iclarityApiClient.put(`api/loyaltyMember/offers/${id}`);
        return response;
    } catch (errors) {
        return rejectWithValue(errors); // errors are set as action.payload and can be used in catch after unwrapResult
    }
});

export const sendGenericEvent = createAsyncThunk('activities/sendGenericEvent', async ({ activityId, data }, { rejectWithValue }) => {
    try {
        const response = await iclarityApiClient.post('api/genericEvents', data);
        return response;
    } catch (errors) {
        return rejectWithValue(errors); // errors are set as action.payload and can be used in catch after unwrapResult
    }
});

const activitiesSlice = createSlice({
    name: 'activities',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(loadActivities.pending, (state, action) => {
                const { sortKey, sortDirection, pageSize, pageNumber, ...filter } = action.meta.arg;
                state.loading = true;
                state.filter = filter;
                state.pagination.sortKey = sortKey;
                state.pagination.sortDirection = sortDirection;
                state.pagination.pageSize = pageSize;
                state.pagination.pageNumber = pageNumber;
            })
            .addCase(loadActivities.fulfilled, (state, action) => {
                const { data, totalCount, totalPages, hasNext, hasPrevious } = action.payload;
                activitiesAdapter.addMany(state, data);
                state.loading = false;
                state.pagination.totalPages = totalPages;
                state.pagination.totalCount = totalCount;
                state.pagination.hasNext = hasNext;
                state.pagination.hasPrevious = hasPrevious;
                state.pagination.ids = data.map(entity => entity.id);
            })
            .addCase(loadActivities.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchActivity.pending, (state, action) => {
                updateActivityUiState(state, action.meta.arg, { fetchRequestStatus: 'loading' });
            })
            .addCase(fetchActivity.fulfilled, (state, action) => {
                activitiesAdapter.upsertOne(state, action.payload);
                updateActivityUiState(state, action.meta.arg, { fetchRequestStatus: 'succeeded' });
            })
            .addCase(fetchActivity.rejected, (state, action) => {
                updateActivityUiState(state, action.meta.arg, { fetchRequestStatus: 'failed' });
            })
            .addCase(redeemActivity.pending, (state, action) => {
                updateActivityUiState(state, action.meta.arg, { redeemRequestStatus: 'loading', redeemRequestError: null });
            })
            .addCase(redeemActivity.fulfilled, (state, action) => {
                updateActivityUiState(state, action.meta.arg, { redeemRequestStatus: 'succeeded' });
            })
            .addCase(redeemActivity.rejected, (state, action) => {
                updateActivityUiState(state, action.meta.arg, { redeemRequestStatus: 'failed', redeemRequestError: action.payload.validationErrorMessage });
            })
            .addCase(sendGenericEvent.pending, (state, action) => {
                const { activityId } = action.meta.arg;
                updateActivityUiState(state, activityId, { eventRequestStatus: 'loading', eventRequestError: null });
            })
            .addCase(sendGenericEvent.fulfilled, (state, action) => {
                const { activityId } = action.meta.arg;
                updateActivityUiState(state, activityId, { eventRequestStatus: 'succeeded' });
            })
            .addCase(sendGenericEvent.rejected, (state, action) => {
                const { activityId } = action.meta.arg;
                updateActivityUiState(state, activityId, { eventRequestStatus: 'failed', eventRequestError: action.payload.validationErrorMessage });
            })
    }
});

const updateActivityUiState = (state, activityId, newState) => {
    const currentActivityState = state.ui[activityId] || initialActivityUiState;
    state.ui[activityId] = { ...currentActivityState, ...newState };
}

export default activitiesSlice.reducer;

export const {
    selectAll: selectAllActivities,
    selectById: selectActivityById
} = activitiesAdapter.getSelectors(state => state.activities);

export const selectPagedActivities = createSelector(
    state => state.activities.entities,
    state => state.activities.pagination,
    (entities, pagination) => pagination.ids.map(id => entities[id])
);

export const selectActivityUiStateById = (state, id) => state.activities.ui[id] || initialActivityUiState;