import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../components/Dialog';
import EnterCodeForm from './EnterCodeForm';
import { useTranslation } from 'react-i18next';

const EnterCodeDialog = ({ show, onSubmit, onCancel }) => {
	const { t } = useTranslation();

	const dialogContent = () => {
		return {
			header: t('Activity_EnterCode'),
			body: renderBody()
		};
	}

	const renderBody = () => {
		return <EnterCodeForm onSubmit={onSubmit} onCancel={onCancel} />;
	}

	return <Dialog content={dialogContent()} show={show} onHide={onCancel} />;
}

EnterCodeDialog.propTypes = {
	show: PropTypes.bool,
	onCancel: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};

export default EnterCodeDialog;