import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from './authSlice';
import RegisterForm from './RegisterForm';
import { useTranslation } from 'react-i18next';
import { withLayout } from '../../layouts';
import AuthPageLayout from '../../layouts/auth';
import InformationMessage from '../../components/InformationMessage';
import { fetchCmsPage } from '../cmsPages/cmsPagesSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const Register = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [showTermsAndConditions, setShowTermnsAndCOnditions] = useState(false);

    const legalInfoPageSlug = 'legal-information';

    const loadTermsAndConditions = () => {
        dispatch(fetchCmsPage(legalInfoPageSlug));
        setShowTermnsAndCOnditions(true);
    }

    const cmsPageLegalInfo = useSelector(state => state.cmsPages[legalInfoPageSlug]);

    return (

        <div>
            <div className='mb-5 mb-md-7'>
                <h1 className='h2'>{t('Register_Title')}</h1>
                <p>{t('Register_Text')}</p>
            </div>
            <RegisterForm onSubmit={async (credentials) => {
                try {
                    const action = await dispatch(registerUser(credentials));
                    unwrapResult(action);
                    history.push('/')
                } catch (errors) {
                    return errors;
                }
            }}
                showTermsAndConditions={() => loadTermsAndConditions()} />
            <InformationMessage
                show={showTermsAndConditions}
                size='lg'
                title={t('TermsAndConditions_Title')}
                message={<div dangerouslySetInnerHTML={{ __html: cmsPageLegalInfo && cmsPageLegalInfo.content }} />}
                onClose={() => setShowTermnsAndCOnditions(false)} />
        </div>
    );
};

export default withLayout(Register, AuthPageLayout, 'vh-lg-120');