import { combineReducers } from '@reduxjs/toolkit';

import auth from '../features/auth/authSlice';
import categories from '../features/categories/categoriesSlice';
import activities from '../features/activities/activitiesSlice';
import account from '../features/account/accountSlice';
import points from '../features/points/pointsSlice';
import rewards from '../features/rewards/rewardsSlice';
import cmsPages from '../features/cmsPages/cmsPagesSlice';

const rootReducer = combineReducers({
    auth,
    account,
    categories,
    activities,
    points,
    rewards,
    cmsPages
});

export default rootReducer;