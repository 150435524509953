import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormLabel, FormControl, FormText } from 'react-bootstrap';

const Radio = ({ entries, horizontal, required, input: { name, onChange, value = '' },
    labelCol = 3, valueCol = 9, label, help, meta: { touched, error, warning, submitError }, disabled, ...props }) => {
    const isInvalid = touched && (error || submitError || warning);

    const formControl = <div className='input-group input-group-md-down-break'>
        {entries && entries.map(entry => <div className='form-control' key={entry.value}>
            <div className='custom-control custom-radio' onClick={() => {
                if (!disabled) {
                    onChange(entry.value);
                }
            }}>
                <input type='radio' className='custom-control-input'
                    name={name} id={`${name}_${entry.value}`}
                    value={entry.value}
                    checked={value === entry.value}
                    disabled={disabled} />
                <label className='custom-control-label'>{entry.label}</label>
            </div>
        </div>)}
    </div>;

    const errorBlock = isInvalid && <FormControl.Feedback type='invalid'>{error || submitError || warning}</FormControl.Feedback>;
    const helpBlock = help && <FormText muted>{help}</FormText>;
    const labelHint = required && <em style={{ color: '#D62229' }}>*</em>;

    if (horizontal) {
        return (
            <FormGroup className='row'>
                <Col as={FormLabel} sm={labelCol} className='col-form-label input-label'>
                    {label}{labelHint}
                </Col>
                <Col sm={valueCol}>
                    {formControl}
                    {errorBlock}
                    {helpBlock}
                </Col>
            </FormGroup>);
    }

    return (
        <FormGroup>
            <FormLabel className='input-label'>{label}{labelHint}</FormLabel>
            {formControl}
            {errorBlock}
            {helpBlock}
        </FormGroup>
    );
};

Radio.propTypes = {
    entries: PropTypes.array,
    horizontal: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    help: PropTypes.string,
    meta: PropTypes.object,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    value: PropTypes.string
};

export default Radio;