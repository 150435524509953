import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackgroundImage from '../../assets/svg/components/abstract-shapes-20.svg';
import { useTranslation } from 'react-i18next';

const BreadcrumbSection = ({ routes, showNavigation, onToggleNavigation, onLogout }) => {
    const { t } = useTranslation();

    return <div className='bg-dark' style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <Container className='space-1 space-top-lg-2 space-bottom-lg-3'>
            <Row className='align-items-center'>
                <Col>
                    <div className='d-none d-lg-block'>
                        <Switch>
                            {routes.map((route, index) => <Route key={index} path={route.path} exact={route.exact}
                                render={props => route.pageTitleResourceKey ? <h1 className='h2 text-white'>{t(route.pageTitleResourceKey)}</h1> : null} />)}
                        </Switch>
                    </div>

                    <ol className='breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0'>
                        <li className='breadcrumb-item'>Account</li>
                        <li className='breadcrumb-item active' aria-current='page'>
                            <Switch>
                                {routes.map((route, index) => <Route key={index} path={route.path} exact={route.exact}
                                    render={props => route.pageTitleResourceKey ? <span>{t(route.pageTitleResourceKey)}</span> : null} />)}
                            </Switch>
                        </li>
                    </ol>
                </Col>

                <Col xs='auto'>
                    <div className='d-none d-lg-block'>
                        <button className='btn btn-sm btn-soft-light' href='#' onClick={onLogout}>Log out</button>
                    </div>

                    {/* Responsive Toggle Button */}
                    <button type='button' className={`navbar-toggler btn btn-icon btn-sm rounde-circle d-lg-none ${!showNavigation ? 'collapsed' : ''}`}
                        aria-label='Toggle navigation'
                        aria-expanded={showNavigation}
                        aria-controls='sidebarNav'
                        onClick={onToggleNavigation}>
                        <span className='navbar-toggler-default'>
                            <svg width={14} height={14} viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                                <path fill='currentColor' d='M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z'></path>
                            </svg>
                        </span>
                        <span className='navbar-toggler-toggled'>
                            <svg width={14} height={14} viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                                <path fill='currentColor' d='M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z'></path>
                            </svg>
                        </span>
                    </button>
                    {/* End Responsive Toggle Button */}
                </Col>
            </Row>
        </Container>
    </div>;
};

export default BreadcrumbSection;