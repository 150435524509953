import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from './authSlice';
import LoginForm from './LoginForm';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withLayout } from '../../layouts';
import AuthPageLayout from '../../layouts/auth';
import { unwrapResult } from '@reduxjs/toolkit';

const Login = ({ location }) => {
    const { isAuthenticated } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    if (isAuthenticated) {
        //const { from } = location.state;
        return <Redirect to={location && location.state ? location.state.from : '/'} />;
    }

    return (
        <div>
            <div className='mb-5 mb-md-7'>
                <h1 className='h2'>{t('Login_Title')}</h1>
                <p>{t('Login_Text')}</p>
            </div>
            <LoginForm onSubmit={async (credentials) => {
                try {
                    const action = await dispatch(loginUser(credentials));
                    unwrapResult(action)
                } catch (errors) {
                    return errors;
                }
            }} />
        </div>
    );
};

export default withLayout(Login, AuthPageLayout);