import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPoints, selectPagedPoints } from './pointsSlice';
import Pagination from '../../components/Pagination';
import PointListItem from './PointListItem';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { selectCurrentAccount } from '../account/accountSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import InviteCodeDialog from './InviteCodeDialog';

const PointsList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const pagination = useSelector(state => state.points.pagination);
    const points = useSelector(selectPagedPoints);
    const account = useSelector(selectCurrentAccount);
    const [showInviteCodeDialog, setShowInviteCodeDialog] = useState(false);

    useEffect(() => {
        const { sortKey, sortDirection, pageNumber, pageSize } = pagination;
        dispatch(loadPoints({ sortKey, sortDirection, pageSize, pageNumber }));
    }, []);

    const handlePageSelect = selectedPageNumber => {
        // search with new page number
        const { sortKey, sortDirection, pageSize } = pagination;
        dispatch(loadPoints({ sortKey, sortDirection, pageSize, pageNumber: selectedPageNumber }));
    };

    return <div>
        <Card className='mb-3 mb-lg-5'>
            <Card.Header>
                <h5 className='card-header-title'>{t("Account_Balance")}</h5>
            </Card.Header>
            <Card.Body>
                <Row className='mx-n2'>
                    <Col sm={6} md={4} className='px-2 mb-3'>
                        <Card className='bg-info h-100 text-center text-white'>
                            <Card.Body>
                                <div className='media align-items-center'>
                                    <span className='avatar avatar-info avatar-lg avatar-circle avatar-border-lg'>
                                        <span className='avatar-initials'><FontAwesomeIcon icon='trophy' className='mb-0' /></span>
                                    </span>
                                    <div className='media-body'>
                                        <h3 className='text-white mb-0'>{account.pointsBalance} {t("Account_Balance_Points")}</h3>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} className='px-2 mb-3'>
                        <Card className='card-frame h-100 text-center'>
                            <Card.Body>
                                <p>{t('InviteCode_CardTitle')}</p>
                                <Button onClick={() => setShowInviteCodeDialog(true)}>{t('InviteCode_Button')}</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        <Card>
            <Card.Header>
                <h5 className='card-header-title'>{t('Points_History')}</h5>
            </Card.Header>
            <Card.Body>
                {points.map(item => <PointListItem item={item} />)}
            </Card.Body>
            <div className='d-flex justify-content-end mt-5'>
                {pagination.totalCount > 0 && <Pagination hideFirstAndLastPageLinks totalPages={pagination.totalPages} currentPage={pagination.pageNumber} onChange={handlePageSelect} />}
            </div>
        </Card>
        <InviteCodeDialog 
            show={showInviteCodeDialog} 
            friendReferralCode={account.friendReferralCode}
            onCancel={() => setShowInviteCodeDialog(false)}/>
    </div>;
};

export default PointsList;