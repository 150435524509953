import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import './App.css';
import Login from '../features/auth/Login';
import Register from '../features/auth/Register';
import ForgottenPassword from '../features/auth/ForgottenPassword';
import Dashboard from '../features/home';
import iclarityApiClient from '../services/iclarityApiClient';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import globalize from '../services/globalize';

library.add(fas, fab);

iclarityApiClient.setTokens({
    accessToken: localStorage['accessToken'],
    refreshToken: localStorage['refreshToken']
});
iclarityApiClient.refreshToken(true);

const App = ({ match }) => {
    const { search } = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(search);
        const queryLang = query.get('lang');
        if (queryLang) {
            globalize.setCurrentCulture(queryLang);
        }
    }, []);

    const loading = <div className='page-preloader'>
        <div className='page-preloader-content-centered'>
            <div className='spinner-grow text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
            </div>
        </div>
    </div>;

    return (
        <Suspense fallback={loading}>
            <Switch>
                <Route path='/login' component={Login} />
                <Route path='/register' component={Register} />
                {/* <Route path='/resetPassword' component={ResetPassword} />*/}
                <Route path='/forgottenPassword' component={ForgottenPassword} />
                <PrivateRoute path='/' component={Dashboard} params={match.params} />
            </Switch>
        </Suspense>
    );
}

export default App;
