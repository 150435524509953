import ActivitiesList from '../activities/ActivitiesList';
import ActivityDetails from '../activities/ActivityDetails';
import AccountPage from '../account';
import PointsList from '../points/PointsList';
import CmsPageDetails from '../cmsPages/CmsPageDetails';

export const ROUTES = [
	{
		path: '/',
		exact: true,
		pageTitleResourceKey: 'ActivitiesList_Title',
		mainContent: ActivitiesList
	},
	{
		path: '/activities/:id',
		exact: true,
		pageTitleResourceKey: 'ActivityDetails_Title',
		mainContent: ActivityDetails
	},
	{
		path: '/account',
		exact: false,
		mainContent: AccountPage
	},
	{
		path: '/points',
		exact: true,
		pageTitleResourceKey: 'PointsList_Title',
		mainContent: PointsList
	},
	{
		path: '/cms/:slug',
		exact: true,
		mainContent: CmsPageDetails
	}
];
