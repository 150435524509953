import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector
} from '@reduxjs/toolkit'
import config from '../../config';
import iclarityApiClient from '../../services/iclarityApiClient';

const categoriesAdapter = createEntityAdapter();

const initialState = categoriesAdapter.getInitialState({
    status: ''
})

// Thunk functions
export const loadCategories = createAsyncThunk(
    'categories/loadCategories',
    async () => {
        const response = await iclarityApiClient.get('api/labels');
        return response;
    },
    {
        condition: (arg, { getState }) => {
            const { status } = getState().categories;
            if (status === 'loading' || status === 'loaded') {
                // Already fetched or in progress, don't need to re-fetch
                return false;
            }
        }
    }
);

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(loadCategories.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(loadCategories.fulfilled, (state, action) => {
                categoriesAdapter.setAll(state, action.payload);
                state.status = 'loaded';
            })
            .addCase(loadCategories.rejected, (state, action) => {
                state.status = '';
            })
    }
});

export default categoriesSlice.reducer;

export const {
    // selectAll: selectAllCategories,
    selectById: selectCategoryById
} = categoriesAdapter.getSelectors(state => state.categories);

export const selectAllCategories = createSelector(
    state => state.categories.entities,
    state => state.categories.ids,
    () => config.VISIBLE_CATEGORIES,
    (entities, ids, visibleCategoryIds) => ids.filter(id => visibleCategoryIds.length === 0 || visibleCategoryIds.includes(id)).map(id => entities[id])
);