import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import iclarityApiClient from '../../services/iclarityApiClient';

// Thunk functions
export const fetchCmsPage = createAsyncThunk('cmsPages/fetchCmsPage',
    async (slug) => await iclarityApiClient.get(`api/cmsPages/content/${slug}`),
    {
        condition: (slug, { getState }) => {
            const { cmsPages } = getState();
            if (cmsPages[slug]) {
                // Already fetched or in progress, don't need to re-fetch
                return false;
            }
        }
    }
);

const cmsPageSlice = createSlice({
    name: 'cmsPages',
    initialState: {},
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchCmsPage.pending, (state, action) => {
                const slug = action.meta.arg;
                state[slug] = {
                    loading: true,
                    content: null,
                    error: false
                };
            })
            .addCase(fetchCmsPage.fulfilled, (state, action) => {
                const slug = action.meta.arg;
                state[slug].loading = false;
                state[slug].content = action.payload;
            })
            .addCase(fetchCmsPage.rejected, (state, action) => {
                const slug = action.meta.arg;
                state[slug].loading = false;
                state[slug].error = true;
            })
    }
});

export default cmsPageSlice.reducer;
