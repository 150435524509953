import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgottenPassword } from './authSlice';
import ForgottenPasswordForm from './ForgottenPasswordForm';
import { useTranslation } from 'react-i18next';
import { withLayout } from '../../layouts';
import AuthPageLayout from '../../layouts/auth';
import { unwrapResult } from '@reduxjs/toolkit';

const ForgottenPassword = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { forgotPasswordSent } = useSelector(state => state.auth);

    return (
        <div>
            <div className='mb-5 mb-md-7'>
                <h1 className='h2'>{t('ForgottenPassword_Title')}</h1>
                <p>{t('ForgottenPassword_Text')}</p>
            </div>
            {forgotPasswordSent && <div className='alert alert-soft-success'>{t('ForgottenPassword_SubmitSuccess')}</div>}
            <ForgottenPasswordForm onSubmit={async (data) => {
                try {
                    const action = await dispatch(forgottenPassword(data.email));
                    unwrapResult(action);
                } catch (errors) {
                    return errors;
                }
            }} />
        </div>
    );
};

export default withLayout(ForgottenPassword, AuthPageLayout);