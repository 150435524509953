import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector
} from '@reduxjs/toolkit'
import iclarityApiClient from '../../services/iclarityApiClient'

const pointsAdapter = createEntityAdapter();

const initialState = pointsAdapter.getInitialState({
    loading: false,
    pagination: {
        sortKey: '',
        sortDirection: '',
        pageSize: 10,
        pageNumber: 1,
        totalPages: 0,
        totalCount: 0,
        hasNext: false,
        hasPrevious: false,
        ids: []
    }
})

// Thunk functions
export const loadPoints = createAsyncThunk('points/loadPoints', async (options) => {
    const { ...pagination } = options;
    const response = await iclarityApiClient.get('api/loyaltyMember/points', { ...pagination });
    return response;
});

const pointsSlice = createSlice({
    name: 'points',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(loadPoints.pending, (state, action) => {
                const { sortKey, sortDirection, pageSize, pageNumber } = action.meta.arg;
                state.loading = true;
                state.pagination.sortKey = sortKey;
                state.pagination.sortDirection = sortDirection;
                state.pagination.pageSize = pageSize;
                state.pagination.pageNumber = pageNumber;
            })
            .addCase(loadPoints.fulfilled, (state, action) => {
                const { data, totalCount, totalPages, hasNext, hasPrevious } = action.payload;
                pointsAdapter.addMany(state, data);
                state.loading = false;
                state.pagination.totalPages = totalPages;
                state.pagination.totalCount = totalCount;
                state.pagination.hasNext = hasNext;
                state.pagination.hasPrevious = hasPrevious;
                state.pagination.ids = data.map(entity => entity.id);
            })
            .addCase(loadPoints.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export default pointsSlice.reducer;

export const {
    selectAll: selectAllPoints
} = pointsAdapter.getSelectors(state => state.points);

export const selectPagedPoints = createSelector(
    state => state.points.entities,
    state => state.points.pagination,
    (entities, pagination) => pagination.ids.map(id => entities[id])
);