import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActivities, selectPagedActivities } from './activitiesSlice';
import { loadCategories, selectAllCategories } from '../categories/categoriesSlice';
import Pagination from '../../components/Pagination';
import ActivityListItem from './ActivityListItem';
import { Container, Row, Col, InputGroup, Form, FormControl, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const ActivitiesList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const filter = useSelector(state => state.activities.filter);
    const pagination = useSelector(state => state.activities.pagination);
    const activities = useSelector(selectPagedActivities);
    const categories = useSelector(selectAllCategories);

    useEffect(() => dispatch(loadCategories()), []);
    useEffect(() => {
        const { sortKey, sortDirection, pageNumber, pageSize } = pagination;
        dispatch(loadActivities({ ...filter, sortKey, sortDirection, pageSize, pageNumber }));
    }, []);

    const [query, setQuery] = useState(filter.query);
    const handleQuerySearchSubmit = (event) => {
        event.preventDefault();
        // search with new query
        const { sortKey, sortDirection, pageNumber, pageSize } = pagination;
        dispatch(loadActivities({ ...filter, query, sortKey, sortDirection, pageSize, pageNumber }));
    };

    const handleCategorySelect = (categoryId, checked) => {
        const checkedCategoryIds = new Set([...filter.categoryIds]);
        if (checked) {
            checkedCategoryIds.add(categoryId);
        } else {
            checkedCategoryIds.delete(categoryId);
        }
        // search with new category Ids
        const { sortKey, sortDirection, pageNumber, pageSize } = pagination;
        dispatch(loadActivities({ ...filter, categoryIds: [...checkedCategoryIds], sortKey, sortDirection, pageSize, pageNumber }));
    };

    const handlePageSelect = selectedPageNumber => {
        // search with new page number
        const { sortKey, sortDirection, pageSize } = pagination;
        dispatch(loadActivities({ ...filter, sortKey, sortDirection, pageSize, pageNumber: selectedPageNumber }));
    };

    const [showFilter, setShowFilter] = useState(false);

    return (
        <Container className='space-2 space-bottom-lg-3'>
            <Row>
                <Col lg={{ span: 3, order: 2 }} className='mb-5 mb-lg-0'>
                    <nav className='navbar navbar-expand-lg'>
                        <button className='navbar-toggler btn btn-block btn-white'
                            aria-controls='filterNavbar'
                            aria-expanded={showFilter}
                            onClick={() => setShowFilter(!showFilter)}>
                            <span className='d-flex justify-content-between align-items-center'>
                                <span className='h5 mb-0'>View filters</span>
                                <span className='navbar-toggler-default'>
                                    <svg width={14} height={14} viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                                        <path fill='currentColor' d='M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z'></path>
                                    </svg>
                                </span>
                                <span className='navbar-toggler-toggled'>
                                    <svg width={14} height={14} viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                                        <path fill='currentColor' d='M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z'></path>
                                    </svg>
                                </span>
                            </span>
                        </button>
                        <Collapse id='filterNavbar' in={showFilter}>
                            <div className='navbar-collapse'>
                                <div className='pt-4 pt-lg-0'>
                                    <Form>
                                        <div className='mb-5'>
                                            <h6 className='small text-cap'>{t("Activity_Categories")}</h6>
                                            {categories && categories.map(x => <div key={x.id} className='d-flex align-items-center justify-content-between mb-2'>
                                                <Form.Check id={`category_${x.id}`} custom label={x.name} checked={filter.categoryIds.includes(x.id)}
                                                    onChange={e => handleCategorySelect(x.id, e.target.checked)} />
                                            </div>)}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Collapse>
                    </nav>
                </Col>
                <Col lg={9}>
                    <Row className='justify-content-md-between align-items-md-center'>
                        <Col md={5} className='mb-3 mb-md-0'>
                            <h3 className='mb-0'> {t('Activity_Count', {count: pagination.totalCount })}</h3>
                        </Col>
                        <Col md={7} lg={6} className='text-md-right'>
                            <Form onSubmit={handleQuerySearchSubmit}>
                                <InputGroup className='input-group-merge'>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><FontAwesomeIcon icon='search' className='mb-0' /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl type='text' placeholder={t("Activity_Search_Placeholder")} value={query} onChange={e => setQuery(e.target.value)} />
                                    <InputGroup.Append>
                                        <Button type='submit'>{t("Activity_Search")}</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                    <hr className='my-4' />
                    {activities && activities.map(x => <ActivityListItem key={x.id} activity={x} />)}
                    <div className='d-flex justify-content-between align-items-center mt-7'>
                        {pagination.totalCount > 0 && <Pagination totalPages={pagination.totalPages} currentPage={pagination.pageNumber} onChange={handlePageSelect} />}
                        {pagination.totalCount > 0 && <small className='d-none d-sm-inline-block text-body'>Page {pagination.pageNumber} out of {pagination.totalPages}</small>}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ActivitiesList;