import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';

const NavDropdownHover = ({ openOnHover, children, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        if (openOnHover) {
            setIsOpen(true);
        }
    }
    const handleMouseLeave = () => {
        if (openOnHover) {
            setIsOpen(false);
        }
    }
    const handleToggle = (open) => {
        if (!openOnHover) {
            setIsOpen(open);
        }
    }

    return <NavDropdown onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onToggle={handleToggle} show={isOpen} {...rest}>
        {children}
    </NavDropdown>;
}

export default NavDropdownHover;