import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { Input } from '../../components/formControls';
import { useTranslation } from 'react-i18next';
import validators, { composeValidators } from '../../services/validators';

const EnterCodeForm = ({ error, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    return (<Form onSubmit={onSubmit} >
        {({ submitError, handleSubmit, pristine, submitting }) => (
            <form onSubmit={handleSubmit} >
                {submitError && <Alert variant='danger'>{submitError}</Alert>}
                <Field name='cashierPin' component={Input} horizontal required labelCol={4} valueCol={8}
                    label={t('Activity_Code')} validate={composeValidators(validators.required, validators.minLength(4))} />
                <div className="d-flex justify-content-end">
                    <Button type='submit' variant='primary' className="mr-2" disabled={pristine || submitting}>
                        {submitting && <Spinner animation='border' size='sm' />} {t('Common_Submit')}
                    </Button>
                    <Button variant='white' onClick={onCancel}>{t('Common_Close')}</Button>
                </div>
            </form>
        )}
    </Form>);
};

EnterCodeForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};
export default EnterCodeForm;
