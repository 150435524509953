import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAccount, updatePersonalDetails } from '../accountSlice';
import PersonalInfoForm from './PersonalInfoForm';

const PersonalInfoPage = () => {
    const dispatch = useDispatch();

    const user = useSelector(selectCurrentAccount);

    const initialValues = user && {
        firstName: user.firstName,
        lastName: user.lastName,
        dateOfBirth: user.dateOfBirth,
        gender: user.gender,
        mobileTelephone: user.mobileTelephone,
        town: user.town,
        country: user.country,
        marketingChannelsOptIn: user.marketingChannelsOptIn && user.marketingChannelsOptIn.map(x => x.name)
    };

    return <PersonalInfoForm initialValues={initialValues} onSubmit={async (data) => {
        try {
            const action = await dispatch(updatePersonalDetails(data));
            unwrapResult(action); // unwrap to catch errors but do not return actual result here - react-final-form would interprets this as error response
        } catch (errors) {
            return errors;  // return validation errors for react-final-form to show
        }
    }} />;

};

export default PersonalInfoPage;