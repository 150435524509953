import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivity, selectActivityById, sendGenericEvent, redeemActivity, selectActivityUiStateById } from './activitiesSlice';
import { Container, Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import globalize from '../../services/globalize';
import { useTranslation } from 'react-i18next';
import ConfirmationMessage from '../../components/ConfirmationMessage';
import EnterCodeDialog from './EnterCodeDialog';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RewardCodeDialog from '../rewards/RewardCodeDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';

const ActivityDetails = ({ match }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const [showConfirmRedeem, setShowConfirmRedeem] = useState(false);
    const [showEnterCodeDialog, setShowEnterCodeDialog] = useState(false);
    const [showRewardCodeDialog, setShowRewardCodeDialog] = useState(false);
    const [receivedReward, setReceivedReward] = useState(null);
    const [redeemPending, setRedeemPending] = useState(false);

    const activityId = Number(match.params.id);
    const activity = useSelector(state => selectActivityById(state, activityId));
    const { eventRequestStatus } = useSelector(state => selectActivityUiStateById(state, activityId));

    useEffect(() => {
        dispatch(fetchActivity(activityId));
    }, []);

    const handleDone = async () => {
        const doneEvent = activity.qualificationEvents.filter(a => a.code.startsWith('done-'))[0];
        if (doneEvent) {
            const genericEvent = {
                storeReference: activity.store && activity.store.reference,
                retailerId: activity.retailer && activity.retailer.id,
                eventCode: doneEvent.code,
                tags: { "offer_id": activity.id }
            };

            try {
                const genericEventAction = await dispatch(sendGenericEvent({ activityId, data: genericEvent }));
                unwrapResult(genericEventAction);

                toast.success(t('Activity_DoneInformationMessage', { count: activity.awardAmount }),
                    { onClose: () => history.push('/') });
            } catch (errors) {
                toast.error(errors.validationErrorMessage || t('Common_Error'));
            }
        }
    };

    const handleEnterCode = async values => {
        const pinEvent = activity.qualificationEvents.find(a => a.code.toLowerCase().startsWith('pin-'));
        if (pinEvent && values.cashierPin) {
            const genericEvent = {
                storeReference: activity.store && activity.store.reference,
                retailerId: activity.retailer && activity.retailer.id,
                eventCode: pinEvent.code,
                cashierPin: values.cashierPin,
                tags: { "offer_id": activity.id }
            }

            try {
                const genericEventAction = await dispatch(sendGenericEvent({ activityId, data: genericEvent }));
                unwrapResult(genericEventAction);

                setShowEnterCodeDialog(false);
                toast.success(t('Activity_CodeAccepted'), { onClose: () => history.push('/') });
            } catch (errors) {
                return errors;
            }
        }
    }

    const handleRedeem = async () => {
        try {
            setShowConfirmRedeem(false);
            setRedeemPending(true);

            const redeemResultAction = await dispatch(redeemActivity(activity.id));
            // unwrap createAsyncThunk action result to get the actual payload (if fulfilled) or throw and error (if rejected)
            const reward = unwrapResult(redeemResultAction);
            if (reward) {
                setReceivedReward(reward);
                setShowRewardCodeDialog(true);
                toast.success(t('Activity_Redeemed'));
            }
            else {
                toast.success(t('Activity_PrizedrawParticipate'), { onClose: () => history.push('/') });
            }
        } catch (errors) {
            toast.error(errors.validationErrorMessage || t('Common_Error'));
        } finally {
            setRedeemPending(false);
        }
    }

    let valueText = '';
    let valueIcon = '';
    if (activity) {
        switch (activity.awardType) {
            case 'Points':
                valueIcon = 'plus-circle';
                valueText = t('Activity_Points', { count: activity.awardAmount })
                break;
            case 'Reward':
                if (activity.conversionType === 'Points' && (activity.value ?? 0) > 0) {
                    valueIcon = 'minus-circle';
                    valueText = t('Activity_Points', { count: activity.value })
                }
                else if (activity.conversionType === 'Money' && (activity.value ?? 0) > 0) {
                    let reward = activity.reward;
                    const locale = globalize.getCurrentCulture();
                    valueText = new Intl.NumberFormat(locale, { style: 'currency', currency: reward.currency })
                        .format(activity.value);
                    valueIcon = 'minus-circle';
                }
                break;
            case 'Information':
            case 'PointsPerMoney':
            case 'PrizeDraw':
            default:
                break;
        }
    }

    const renderActionButtons = () => {
        const showDoneBtn = activity.qualificationEvents.some(event => event.code.toLowerCase().startsWith('done-'));
        const showPinButton = activity.qualificationEvents.some(event => event.code.toLowerCase().startsWith('pin-'));

        const eventRequestPending = eventRequestStatus === 'loading';

        if (showDoneBtn) {
            return <Button variant='primary' block className='transition-3d-hover'
                disabled={eventRequestPending}
                onClick={handleDone}>
                {eventRequestPending && <Spinner animation='border' size='sm' />} {t('Activity_DoneButton')}
            </Button>;
        }

        if (showPinButton) {
            return <Button variant='primary' block className='transition-3d-hover' onClick={() => setShowEnterCodeDialog(true)}>{t('Activity_CodeButton')}</Button>;
        }

        if (activity.awardType.toLowerCase() === 'information' && activity.linkUrl) {
            return <Button variant='primary' block className='transition-3d-hover' onClick={() => window.open(activity.linkUrl, "_blank")}>{t('Activity_SeeMoreButton')}</Button>;
        }
        else if (activity.conversionType === 'Money' && activity.awardType === 'Reward') {
            return <Button variant='primary' block className='transition-3d-hover' onClick={() => alert('Not implemented')}>{t('Activity_BuyButton')}</Button>;
        }
        else if (activity.allowRedeem) {
            return <Button variant='primary' block className='transition-3d-hover'
                disabled={redeemPending}
                onClick={() => activity.value > 0 ? setShowConfirmRedeem(true) : handleRedeem()}>
                {redeemPending && <Spinner animation='border' size='sm' />} {t('Activity_GetButton')}
            </Button>;
        }
    }

    return <div>{activity && <div className='position-relative'>
        <div className='gradient-y-overlay-lg-white bg-img-hero space-2'>
            <Container>
                <Row>
                    <Col md={7} lg={8}>
                        <span className='badge badge-pill badge-success text-uppercase mb-2'>{t(`AwardType_${activity.awardType}`)}</span>
                        <h1 className='text-lh-sm'>{activity.name}</h1>
                        <div className='pt-7 mt-7'>
                            <p dangerouslySetInnerHTML={{ __html: activity.description }} />
                        </div>
                    </Col>
                    <Col md={5} lg={4} className='position-relative z-index-2'>
                        <Card className='card-bordered position-sticky'>
                            <Card.Img variant='top' src={activity.imageUrl} />
                            <Card.Body>
                                <div className='mb-3'>
                                    <span className='h2 text-lh-sm mr-1 mb-0'>{activity.store && activity.store.name}</span>
                                </div>
                                <div className='mb-4'>
                                    {renderActionButtons()}
                                </div>
                                {valueText && <div className='media text-body font-size-1 mb-2'>
                                    <div className='min-w-3rem text-center mr-3'>
                                        <span><FontAwesomeIcon icon={valueIcon} className='mb-0' /></span>
                                    </div>
                                    <div className='media-body'>{valueText}</div>
                                </div>}
                                <div className='media text-body font-size-1 mb-2'>
                                    <div className='min-w-3rem text-center mr-3'>
                                        <span><FontAwesomeIcon icon='calendar' className='mb-0' /></span>
                                    </div>
                                    <div className='media-body'><span className='text-muted'>{t('Activity_Start')}</span> {moment(activity.startDate).format('LL')}</div>
                                </div>
                                <div className='media text-body font-size-1 mb-2'>
                                    <div className='min-w-3rem text-center mr-3'>
                                        <span><FontAwesomeIcon icon='calendar' className='mb-0' /></span>
                                    </div>
                                    <div className='media-body'><span className='text-muted'>{t('Activity_End')}</span> {moment(activity.endDate).format('LL')}</div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

        <Container className='space-top-md-2 position-md-absolute top-0 right-0 left-0'>
            <Row className='justify-content-end'>

            </Row>
        </Container>
        <EnterCodeDialog
            show={showEnterCodeDialog}
            title={t('Activity_EnterCodeTitle')}
            onSubmit={(values) => handleEnterCode(values)}
            onCancel={() => setShowEnterCodeDialog(false)}
        />
        <ConfirmationMessage
            show={showConfirmRedeem}
            title={t('Activity_ConfirmRedeemTitle')}
            message={t('Activity_ConfirmRedeemMessage', { value: activity.value })}
            onConfirm={handleRedeem}
            onCancel={() => setShowConfirmRedeem(false)}
        />
        <RewardCodeDialog
            show={showRewardCodeDialog}
            code={receivedReward && receivedReward.code}
            id={receivedReward && receivedReward.id}
            onRedeem={() => { setShowRewardCodeDialog(false); history.push('/'); }}
            onCancel={() => { setShowRewardCodeDialog(false); history.push('/'); }} />
        <ToastContainer />
    </div>}
    </div>;
};

export default ActivityDetails;