import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '../../components/Dialog';
import { useTranslation } from 'react-i18next';
import { Button, Card, Spinner } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { redeemReward, selectRewardUiStateById } from './rewardsSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const RewardCodeDialog = ({ show, id, code, onCancel, onRedeem }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dialogContent = () => {
        return {
            header: t('Reward_Code_Title'),
            body: renderBody()
        };
    };

    const { redeemRequestStatus } = useSelector(state => selectRewardUiStateById(state, id));
    const redeemPending = redeemRequestStatus === 'loading';

    const handleRedeem = async () => {
        try {
            const redeemResultAction = await dispatch(redeemReward(id));
            // unwrap createAsyncThunk action result to get the actual payload (if fulfilled) or throw and error (if rejected)
            unwrapResult(redeemResultAction);
            if (onRedeem) {
                onRedeem();
            }
        } catch (errors) {
            toast.error(errors.validationErrorMessage || t('Common_Error'));
        }
    }

    const renderBody = () => {
        return <div>
            <p>{t('Reward_Code_Description')}</p>
            <Card className='card-bordered shadow-none'>
                <Card.Body className='card-body-centered'>
                    <CopyToClipboard text={code} className=''
                        onCopy={() => toast.success(t('Reward_Code_Copied'))}>
                        <div style={{ cursor: 'pointer' }}>{code} <FontAwesomeIcon icon='copy' className='mb-0' /></div>
                    </CopyToClipboard>
                </Card.Body>
            </Card>
            <p className='mt-3'>{t('Reward_UseInfo')}</p>

            <div className='d-flex justify-content-end mt-3'>
                <Button variant='primary' className='btn btn-primary mr-2'
                    disabled={redeemPending}
                    onClick={handleRedeem}>
                    {redeemPending && <Spinner animation='border' size='sm' />} {t('Reward_Use')}
                </Button>
                <Button variant='white' onClick={onCancel}>{t('Common_Close')}</Button>
            </div>
            <ToastContainer />
        </div>;
    };

    return <Dialog content={dialogContent()} show={show} onHide={onCancel} />;
}

RewardCodeDialog.propTypes = {
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onRedeem: PropTypes.func.isRequired
};

export default RewardCodeDialog;