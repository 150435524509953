import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';
import { logoutUser } from '../auth/authSlice';
import { selectCurrentAccount } from './accountSlice';
import * as Routes from './routes';
import BreadcrumbSection from './BreadcrumbSection';
import Sidebar from './Sidebar';

const AccountDashboard = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentAccount);
    const [showNavigation, setShowNavigation] = useState(false);

    const routes = Routes.ROUTES;

    return <div className='bg-light'>
        <BreadcrumbSection routes={routes}
            showNavigation={showNavigation} onToggleNavigation={() => setShowNavigation(!showNavigation)}
            onLogout={() => dispatch(logoutUser())} />

        <Container className='space-1 space-top-lg-0 space-bottom-lg-2 mt-lg-n10'>
            <Row>
                <Col lg={3}>
                    <Sidebar showNavigation={showNavigation} user={user} onLogout={() => dispatch(logoutUser())} />
                </Col>
                <Col lg={9}>
                    <Switch>
                        {routes.map((route, index) => <Route key={index} path={route.path} exact={route.exact}
                            component={route.mainContent} />)}
                    </Switch>
                </Col>

            </Row>
        </Container>


    </div>;
};

export default AccountDashboard;