import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch } from 'react-redux';
import { changePassword } from '../accountSlice';
import ChangePasswordForm from './ChangePasswordForm';

const SecurityPage = () => {
    const dispatch = useDispatch();

    return <ChangePasswordForm onSubmit={async (data) => {
        try {
            const action = await dispatch(changePassword(data));
            unwrapResult(action);
        } catch (errors) {
            return errors;
        }
    }} />;
};

export default SecurityPage;