import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormText, Form, FormLabel, FormControl } from 'react-bootstrap';

const CheckboxGroup = ({ horizontal, className, labelCol = 3, valueCol = 9, input, entries, required, inline, label, staticLabel, help, meta: { touched, error, warning, submitError }, ...props }) => {
	const isInvalid = touched && (error || submitError || warning);

	const checkedValues = new Set(input.value || []);
	const entryOnChange = (value, checked) => {
		if (checked) {
			checkedValues.add(value);
		} else {
			checkedValues.delete(value);
		}
		input.onChange([...checkedValues]);
	};

	const formControls = entries && entries.map((x, index) => <Form.Check key={index} type='checkbox'
		label={x.label} inline={inline} custom id={`${input.name}-${x.value}`}
		onChange={e => entryOnChange(x.value, e.target.checked)}
		checked={checkedValues.has(x.value)} {...props}
	/>);

	const errorBlock = isInvalid && <FormControl.Feedback type='invalid'>{error || submitError || warning}</FormControl.Feedback>;
	const helpBlock = help && <FormText muted>{help}</FormText>;
	const labelHint = required && <em style={{ color: '#D62229' }}>*</em>;

	if (horizontal) {
		return (
			<FormGroup className={`${className} row`}>
				<Col as={FormLabel} sm={labelCol} className='col-form-label input-label'>
					{label}{labelHint}
				</Col>
				<Col sm={valueCol}>
					{formControls}
					{errorBlock}
					{helpBlock}
				</Col>
			</FormGroup>);
	}

	return (
		<FormGroup className={className}>
			<FormLabel className='input-label'>{label}{labelHint}</FormLabel>
			{formControls}
			{errorBlock}
			{helpBlock}
		</FormGroup>
	);
};

CheckboxGroup.propTypes = {
	horizontal: PropTypes.bool,
	input: PropTypes.object,
	label: PropTypes.string,
	help: PropTypes.string,
	meta: PropTypes.object,
	placeholder: PropTypes.string,
	disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	componentClass: PropTypes.string,
	value: PropTypes.string
};

export default CheckboxGroup;