import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Card, Button, Alert, Spinner } from 'react-bootstrap';
import { Input } from '../../../components/formControls';
import { useTranslation } from 'react-i18next';
import validators, { composeValidators } from '../../../services/validators';

const passwordMinLength = validators.minLength(6);

const ChangePasswordForm = ({ onSubmit, initialValues }) => {
    const { t } = useTranslation();
    return <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ submitError, submitSucceeded, handleSubmit, pristine, submitting, values }) => (
            <form onSubmit={handleSubmit}>
                <Card>
                    <Card.Header className='mb-3 mb-lg-5'>
                        <h5 className='card-header-title'>{t('ChangePassword_Title')}</h5>
                    </Card.Header>
                    <Card.Body>
                        {submitError && <Alert variant='danger'>{submitError}</Alert>}
                        {submitSucceeded && <Alert variant='success'>{t('Common_UpdateSuccess')}</Alert>}
                        <Field name='currentPassword' component={Input} type='password' horizontal
                            label={t('ChangePassword_CurrentPassword')} placeholder={t('ChangePassword_CurrentPassword')}
                            validate={validators.required} />
                        <Field name='newPassword' component={Input} type='password' horizontal
                            label={t('ChangePassword_NewPassword')} placeholder={t('ChangePassword_NewPassword')}
                            validate={composeValidators(validators.required, passwordMinLength)} />
                        <Field name='confirmNewPassword' component={Input} type='password' horizontal
                            label={t('ChangePassword_ConfirmNewPassword')} placeholder={t('ChangePassword_ConfirmNewPassword')}
                            validate={composeValidators(validators.required, passwordMinLength, validators.equalPassword(values.newPassword))} />
                    </Card.Body>
                    <Card.Footer className='d-flex justify-content-end'>
                        <Button type='submit' variant='primary' disabled={pristine || submitting}>
                            {submitting && <Spinner animation='border' size='sm' />} {t('ChangePassword_Save')}
                        </Button>
                    </Card.Footer>
                </Card>
            </form>
        )}
    </Form>;
};

ChangePasswordForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default ChangePasswordForm;