import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './app/App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './assets/css/theme.css';
import './index.css';
import { requestRefreshToken, receiveRefreshToken, refreshTokenError, logoutUser } from './features/auth/authSlice';
import iclarityApiClient from './services/iclarityApiClient';
import globalize from '../src/services/globalize';

iclarityApiClient.init({
  onRequestRefreshToken: initializing => store.dispatch(requestRefreshToken(initializing)),
  onRequestRefreshTokenSuccess: ({ accessToken, refreshToken }) => store.dispatch(receiveRefreshToken({ accessToken, refreshToken })),
  onRequestRefreshTokenFailure: errorMessage => {
    store.dispatch(refreshTokenError(errorMessage));
    store.dispatch(logoutUser(false));
  }
});

globalize.init();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Route component={App} />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
