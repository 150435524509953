import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import globalize from '../../services/globalize';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import RewardCodeDialog from './RewardCodeDialog';
import { loadRewards } from './rewardsSlice';

const RewardListItem = ({ item }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [showRewardCodeDialog, setShowRewardCodeDialog] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const { reward, store, retailer, created, validFromDate, expirationDate, redemptionDate, code, id } = item;

    const storeName = store ? store.name : retailer ? retailer.name : null;

    let formattedRewardValue = reward.value;
    const locale = globalize.getCurrentCulture();
    switch (reward.type) {
        case 'MoneyOff':
            formattedRewardValue = new Intl.NumberFormat(locale, { style: 'currency', currency: reward.currency })
                .format(reward.value);
            break;
        case 'PercentOff':
            formattedRewardValue = new Intl.NumberFormat(locale, { style: 'percent' })
                .format(reward.value / 100);
            break;
        case 'Gift':
            formattedRewardValue = '';
            break;
        default:
            break;
    }

    const expired = moment(expirationDate).isBefore(moment());
    const used = redemptionDate !== null && redemptionDate !== undefined;
    const active = !expired && !used;

    return <div><Card className='card-bordered shadow-none mb-3'>
        <Card.Body>
            <div className='d-sm-flex'>
                <div className='media align-items-center align-items-sm-start mb-3'>
                    <img className='avatar avatar-sm mr-3' src={reward.imageUrl} alt='Reward' />
                    {storeName && <div className='media-body d-sm-none'>
                        <h6 className='mb-0 text-body'>
                            <FontAwesomeIcon icon='map-marker-alt' className='mb-0' /> <span>{storeName}</span>
                        </h6>
                    </div>}
                </div>

                <div className='media-body'>
                    <Row>
                        <Col className='col col-md-8'>
                            <h3 className={`mb-0 ${active ? 'text-dark' : 'text-body'}`}>{reward.name}</h3>
                            {storeName && <div className='d-none d-sm-inline-block'>
                                <h6 className='mb-0 text-body'>
                                    <FontAwesomeIcon icon='map-marker-alt' className='mb-0' /> <span>{storeName}</span>
                                </h6>
                            </div>}
                            {reward.description && <div><a className='text-muted small' role='button' onClick={() => setShowDescription(!showDescription)}>
                                {showDescription ?
                                    <span><span>{t('Reward_ShowLess')}</span> <FontAwesomeIcon icon='angle-up' className='mb-0' /></span> :
                                    <span><span>{t('Reward_ShowMore')}</span> <FontAwesomeIcon icon='angle-down' className='mb-0' /></span>}
                            </a></div>}
                            {showDescription && <p dangerouslySetInnerHTML={{ __html: reward.description }} />}
                        </Col>
                        <Col className='col-auto order-xl-3'>
                            {active && code && <button className='btn btn-xs btn-outline-primary' onClick={() => setShowRewardCodeDialog(true)}>
                                {t('Reward_ShowCode')}
                            </button>}
                            {!used && expired && <span className='badge badge-dark badge-pill'>{t('Reward_Expired')}</span>}
                            {used && <span className='badge badge-info badge-pill'>{t('Reward_Used')}</span>}
                        </Col>
                        <Col className='col-12 col-xl mt-3 mt-xl-0'>
                            <span className='d-block font-size-1 text-body mb-1'>{formattedRewardValue}</span>
                            <span className='badge badge-soft-info'><span className='legend-indicator bg-info' />{t(`RewardType_${reward.type}`)}</span>
                        </Col>
                    </Row>
                </div>
            </div>
        </Card.Body>
        <Card.Footer>
            <ul className='list-inline list-separator small text-body'>
                <li className='list-inline-item'>{moment(created).fromNow()}</li>
                {validFromDate && <li className='list-inline-item'>{`${t('Reward_ValidFrom')} ${moment(validFromDate).format('L')}`}</li>}
                {expirationDate && <li className='list-inline-item'>{`${t('Reward_UseBy')} ${moment(expirationDate).format('L')}`}</li>}
            </ul>
        </Card.Footer>
    </Card>
        {
            code && showRewardCodeDialog && <RewardCodeDialog
                show={showRewardCodeDialog}
                code={code}
                id={id}
                onRedeem={() => {
                    setShowRewardCodeDialog(false);
                    dispatch(loadRewards({ onlyActive: true, sortKey: "", sortDirection: "", pageSize: 10, pageNumber: 1 }))
                }}
                onCancel={() => setShowRewardCodeDialog(false)} />
        }
        <ToastContainer />
    </div >;
};

export default RewardListItem;